import React, { useState, useEffect, useRef } from "react";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import watermark from "./../../assets/images/logo_font_blue.png";
import watermarkPng from "./../../assets/images/logo_font_blue_png.png";
import sampleDiamond from "./../../assets/images/sampleDiamond.png";
function Rebrand() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();

  const [rebrandData, setRebrandData] = useState({
    diamond_id: "",
    supplier_id: "",
    // access_key: "",
    client_id: "",
    web_url: "",
    height: "",
    width: "",
    // opacity: "",
    // expired_key: "",
    // left: "",
    // right: "",
    // top: "",
    // bottom: "",
    top_bottom: "",
    // top_bottom_value: "",
    left_right: "",
    // left_right_value: "",
  });
  const [image, setImage] = useState("");

  const [strdDiamondId, setStrdDiamondId] = useState("");
  const [strdSupplierId, setStrdSupplierId] = useState("");
  // const [strdAccessKey, setStrdAccessKey] = useState("");
  const [strdClientId, setStrdClientId] = useState("");
  const [strdWebUrl, setStrdWebUrl] = useState("");
  const [strdWidth, setStrdWidth] = useState("");
  const [strdHeight, setStrdHeight] = useState("");
  // const [strdOpacity, setStrdOpacity] = useState("");
  // const [strdLeft, setStrdLeft] = useState("");
  // const [strdRight, setStrdRight] = useState("");
  // const [strdTop, setStrdTop] = useState("");
  // const [strdBottom, setStrdBottom] = useState("");
  const [strdTopBottom, setStrdTopBottom] = useState("");
  const [strdLeftRight, setStrdLeftRight] = useState("");
  // const [strdTopBottomVal, setStrdTopBottomVal] = useState("");
  // const [strdLeftRightVal, setStrdLeftRightVal] = useState("");

  const storeInlocal = () => {
    localStorage.setItem("diamond_id", rebrandData.diamond_id);
    localStorage.setItem("supplier_id", rebrandData.supplier_id);
    // localStorage.setItem("access_key", rebrandData.access_key);
    localStorage.setItem("client_id", rebrandData.client_id);
    localStorage.setItem("web_url", rebrandData.web_url);
    localStorage.setItem("width", rebrandData.width || "100px");
    localStorage.setItem("height", rebrandData.height || "30px");
    // localStorage.setItem("opacity", rebrandData.opacity);
    // localStorage.setItem(
    //   "left",
    //   rebrandData.left === "" ? "unset" : rebrandData.left
    // );
    // localStorage.setItem(
    //   "right",
    //   rebrandData.right === "" ? "unset" : rebrandData.right
    // );
    // localStorage.setItem(
    //   "top",
    //   rebrandData.top === "" ? "unset" : rebrandData.top
    // );
    // localStorage.setItem(
    //   "bottom",
    //   rebrandData.bottom === "" ? "unset" : rebrandData.bottom
    // );
    // ============================
    localStorage.setItem("top_bottom", rebrandData.top_bottom);
    // localStorage.setItem("top_bottom_value", rebrandData.top_bottom_value);
    localStorage.setItem("left_right", rebrandData.left_right);
    // localStorage.setItem("left_right_value", rebrandData.left_right_value);
    setTimeout(() => {
      getLocalItems();
    }, 600);
    setTimeout(() => {
      getData();
    }, 1000);
  };

  const getLocalItems = () => {
    setStrdDiamondId(localStorage.getItem("diamond_id"));
    setStrdSupplierId(localStorage.getItem("supplier_id"));
    // setStrdAccessKey(localStorage.getItem("access_key"));
    setStrdClientId(localStorage.getItem("client_id"));
    setStrdWebUrl(localStorage.getItem("web_url"));
    setStrdWidth(localStorage.getItem("width"));
    setStrdHeight(localStorage.getItem("height"));
    // setStrdOpacity(localStorage.getItem("opacity"));
    // setStrdLeft(localStorage.getItem("left"));
    // setStrdRight(localStorage.getItem("right"));
    // setStrdTop(localStorage.getItem("top"));
    // setStrdBottom(localStorage.getItem("bottom"));
    // ============================
    setStrdLeftRight(localStorage.getItem("left_right"));
    // setStrdLeftRightVal(localStorage.getItem("left_right_value"));
    setStrdTopBottom(localStorage.getItem("top_bottom"));
    // setStrdTopBottomVal(localStorage.getItem("top_bottom_value"));
  };

  const removeLocalItem = () => {
    localStorage.removeItem("diamond_id");
    localStorage.removeItem("supplier_id");
    // localStorage.removeItem("access_key");
    localStorage.removeItem("client_id");
    localStorage.removeItem("web_url");
    localStorage.removeItem("width");
    localStorage.removeItem("height");
    // localStorage.removeItem("opacity");
    // localStorage.removeItem("left");
    // localStorage.removeItem("right");
    // localStorage.removeItem("top");
    // localStorage.removeItem("bottom");
    // ============================
    localStorage.removeItem("left_right");
    // localStorage.removeItem("left_right_value");
    localStorage.removeItem("top_bottom");
    // localStorage.removeItem("top_bottom_value");
  };

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setRebrandData({ ...rebrandData, [name]: value });
    console.log({ ...rebrandData, [name]: value });
  };

  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  // For getting data
  const [data, setData] = useState([]);
  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-rebrand-data-dId/${id}/${strdDiamondId}/${strdClientId}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setData(result.data);
  };
  // For Submitting Data
  const rebrandSubmit = async (e) => {
    e.preventDefault();
    document.getElementById(
      "saveChangesBtn"
    ).innerHTML = `<span class="load loading saveChangesBtn"></span>`;

    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL = SERVER_URL + `/api/admin/rebrand-template`;

    // if (!rebrandData.name || !rebrandData.phone) {
    //   return false;
    // }

    // const {
    //   diamond_id,
    //   supplier_id,
    //   access_key,
    //   client_id,
    //   web_url,
    //   height,
    //   width,
    //   opacity,
    //   top_bottom,
    //   top_bottom_value,
    //   left_right,
    //   left_right_value,
    // } = rebrandData;

    const formData = new FormData();
    formData.append("admin_id", id);
    formData.append("diamond_id", strdDiamondId);
    formData.append("supplier_id", strdSupplierId);
    // formData.append("access_key", strdAccessKey);
    formData.append("client_id", strdClientId);
    formData.append("web_url", strdWebUrl);
    formData.append("height", strdHeight);
    formData.append("width", strdWidth);
    // formData.append("opacity", strdOpacity);
    formData.append("top_bottom", strdTopBottom);
    // formData.append("top_bottom_value", strdTopBottomVal);
    formData.append("left_right", strdLeftRight);
    // formData.append("left_right_value", strdLeftRightVal);
    formData.append("watermark", image);
    console.log(formData);
    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
      body: formData,
    });
    const data = await res.json();
    if (res.ok) {
      window.alert(`${data.message}`);
      setTimeout(() => {
        document.getElementById("saveChangesBtn").innerHTML = `Save Template`;
      }, 500);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
    } else {
      window.alert(`${data.message}`);
      setTimeout(() => {
        document.getElementById("saveChangesBtn").innerHTML = `Save Template`;
      }, 500);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      // document.getElementById("reset").reset();
      // setImage("");
    }
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Rebrand Template</h4>

              {/* <div>
                <button className="btn btn-dark" onClick={buttonClick}>
                  Click check
                </button>
              </div> */}

              <form
                className="forms-sample"
                autoComplete="off"
                // onSubmit={rebrandSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputDiamondId">
                        Diamond Id *
                      </label>
                      <input
                        type="text"
                        name="diamond_id"
                        className="form-control"
                        id="exampleInputDiamondId"
                        placeholder="Diamond Id"
                        defaultValue={
                          rebrandData.diamond_id ||
                          (rebrandData.diamond_id = strdDiamondId)
                        }
                        onChange={handleFormData}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputSupplierId">
                        Supplier Id *
                      </label>
                      <input
                        type="text"
                        name="supplier_id"
                        className="form-control"
                        id="exampleInputSupplierId"
                        placeholder="Supplier Id"
                        defaultValue={
                          rebrandData.supplier_id ||
                          (rebrandData.supplier_id = strdSupplierId)
                        }
                        onChange={handleFormData}
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Expiry Key</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputExpiryKey1"
                        placeholder="Expiry Key"
                        name="expiry_key"
                        defaultValue={rebrandData.expired_key}
                        onChange={handleFormData}
                      />
                    </div>
                  </div> */}

                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputAccessKey1">
                        Access Key *
                      </label>
                      <input
                        type="text"
                        name="access_key"
                        defaultValue={
                          rebrandData.access_key ||
                          (rebrandData.access_key = strdAccessKey)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Access Key"
                      />
                    </div>
                  </div> */}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="client_id">Client Id *</label>
                      <input
                        type="text"
                        name="client_id"
                        id="client_id"
                        defaultValue={
                          rebrandData.client_id ||
                          (rebrandData.client_id = strdClientId)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Client Id"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="image">Watermark *</label>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        ref={fileInputRef}
                        onChange={handleImageData}
                        className="file-upload-default"
                      />
                      <div className="input-group col-xs-12">
                        <input
                          type="text"
                          className="form-control file-upload-info"
                          style={{ cursor: "pointer" }}
                          id="file-upload-info"
                          readOnly
                          placeholder={image ? image.name : "Upload Image"}
                          onChange={handleImageData}
                          // placeholder="Upload Image"
                          onClick={handleUploadButtonClick}
                        />
                        <span className="input-group-append">
                          <button
                            className="file-upload-browse btn btn-primary"
                            type="button"
                            onClick={handleUploadButtonClick}
                          >
                            Upload
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="web_url">
                        Web Url *{" "}
                        <span style={{ fontSize: "11px", fontWeight: "600" }}>
                          (Note: Please include "https://" in Url)
                        </span>
                      </label>
                      <input
                        type="text"
                        name="web_url"
                        id="web_url"
                        defaultValue={
                          rebrandData.web_url ||
                          (rebrandData.web_url = strdWebUrl)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Web Url (Ex - https://www.diamondsoncall.com/)"
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputHeight">Height *</label>
                      <input
                        type="text"
                        name="height"
                        id="exampleInputHeight"
                        defaultValue={
                          rebrandData.height ||
                          (rebrandData.height = strdHeight)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Height (Ex- 30px)"
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputWidth">Width *</label>
                      <input
                        type="text"
                        name="width"
                        id="exampleInputWidth"
                        defaultValue={
                          rebrandData.width || (rebrandData.width = strdWidth)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Width (Ex- 100px)"
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      {/* <label htmlFor="exampleInputLeft1"> */}
                      <div className="form-group">
                        <label htmlFor="exampleSelectTop/Bottom">
                          Top/Bottom *
                        </label>
                        <select
                          className="form-control"
                          id="exampleSelectTop/Bottom"
                          name="top_bottom"
                          defaultValue={
                            rebrandData.top_bottom ||
                            (rebrandData.top_bottom = "top") ||
                            (rebrandData.top_bottom = strdTopBottom)
                          }
                          onChange={handleFormData}
                        >
                          <option disabled>Select Top/Bottom</option>
                          <option value="top">Top</option>
                          <option value="bottom">Bottom</option>
                        </select>
                      </div>
                      {/* </label> */}
                      {/* <input
                        type="text"
                        name="top_bottom_value"
                        defaultValue={
                          rebrandData.top_bottom_value ||
                          (rebrandData.top_bottom_value = "4px") ||
                          (rebrandData.top_bottom_value = strdTopBottomVal)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Enter Top/Bottom Value (Ex: 4px)"
                      /> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      {/* <label htmlFor="exampleInputLeft1"> */}
                      <div className="form-group">
                        <label htmlFor="exampleSelectLeft/Right">
                          Left/Right *
                        </label>
                        <select
                          className="form-control"
                          id="exampleSelectLeft/Right"
                          name="left_right"
                          defaultValue={
                            rebrandData.left_right ||
                            (rebrandData.left_right = "left") ||
                            (rebrandData.left_right = strdLeftRight)
                          }
                          onChange={handleFormData}
                        >
                          <option disabled>Select Left/Right</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                        </select>
                      </div>
                      {/* </label> */}
                      {/* <input
                        type="text"
                        name="left_right_value"
                        defaultValue={
                          rebrandData.left_right_value ||
                          (rebrandData.left_right_value = "4px") ||
                          (rebrandData.left_right_value = strdLeftRightVal)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Enter Left/Right Value (Ex: 4px)"
                      /> */}
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputOpacity1">Opacity *</label>
                      <input
                        type="text"
                        name="opacity"
                        defaultValue={
                          rebrandData.opacity ||
                          (rebrandData.opacity = strdOpacity)
                        }
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Opacity"
                      />
                    </div>
                  </div> */}

                  {/* <div className="col-md-3">
                    <div className="form-group">
                      <label for="exampleInputLeft1">Left</label>
                      <input
                        type="text"
                        name="left"
                        defaultValue={rebrandData.left}
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Left"
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label for="exampleInputRight1">Right</label>
                      <input
                        type="text"
                        name="right"
                        defaultValue={rebrandData.right}
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Right"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label for="exampleInputTop1">Top</label>
                      <input
                        type="text"
                        name="top"
                        defaultValue={rebrandData.top}
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Top"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label for="exampleInputBottom1">Bottom</label>
                      <input
                        type="text"
                        name="bottom"
                        defaultValue={rebrandData.bottom}
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Bottom"
                      />
                    </div>
                  </div> */}

                  {/* <div className="col-md-6 mb-3">
                    <div className="form-group mb-3">
                      <div className="form-check form-check-flat form-check-primary">
                        <label className="form-check-label">
                          Show Password{" "}
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => hideShowPassword()}
                          />
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary mr-2 px-5 py-3"
                    id="loginBtn"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={storeInlocal}
                  >
                    View Template
                  </button>
                </div>

                {/* <div className="form-group">
                    <button
                      id="loginBtn"
                      type="submit"
                      className="form-control btn btn-primary rounded signInBtn px-3"
                    >
                      Sign In
                    </button>
                  </div> */}
              </form>
              {/* <!-- Modal --> */}
              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content" style={{ width: "800px" }}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Save Template
                      </h5>
                      <button
                        type="button"
                        className="close btnClose"
                        data-dismiss="modal"
                        aria-label="Close"
                        // onClick={removeLocalItem}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body" style={{ padding: "0px" }}>
                      <div
                        className="box"
                        style={{
                          width: "606px",
                          height: "477px",
                        }}
                      >
                        <img src={sampleDiamond} alt="" />

                        <img
                          src={SERVER_URL + "/watermarks/" + data?.watermark}
                          alt=""
                          style={{
                            position: `absolute`,
                            width: `${strdWidth}`,
                            height: `${strdHeight}`,
                            [strdTopBottom]: `4px`,
                            [strdLeftRight]: `4px`,
                            // [strdTopBottom]: `${strdTopBottomVal}`,
                            // [strdLeftRight]: `${strdLeftRightVal}`,
                            opacity: `0.5`,
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        // onClick={removeLocalItem}
                      >
                        Close
                      </button>
                      <button
                        id="saveChangesBtn"
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-dark appButton"
                        onClick={rebrandSubmit}
                      >
                        Save Template
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rebrand;
