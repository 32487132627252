import React from "react";
import WebRoutes from "./Components/Routes/WebRoutes";
import AlertTemplate from "./Components/Common/AlertTemplate";
import { Provider, transitions } from "react-alert";
import { Toaster } from "react-hot-toast";

const options = {
  timeout: 5000,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 100,
  },
};

function App() {
  return (
    <Provider template={AlertTemplate} {...options}>
      <WebRoutes />
      <Toaster
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
            borderRadius:"2px"
          },
        }}
      />
    </Provider>
  );
}

export default App;
