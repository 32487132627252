import React, { useState, useEffect, useRef } from "react";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function UploadLinks() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();
  const admin_id = localStorage.getItem("adminId")?.replace(/"/g, "");
  // const [storeLinks, setStoreLinks] = useState([]);
  const [storeFile, setStoreFile] = useState("");
  // const [fileName, setFileName] = useState("");
  const handleFileData = (e) => {
    // console.log(e);
    setStoreFile(e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  //   function handleFileUpload(event) {
  //     event.preventDefault();
  //     // const file = storeFile;
  //     const file = event.target.files[0]
  //     setFileName(file)
  //     const reader = new FileReader();
  //     reader.onload = function (event) {
  //       const data = event.target.result;
  //       const workbook = XLSX.read(data, { type: "binary" });
  //       const firstSheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[firstSheetName];
  //       const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //       console.log(jsonData);
  //       setStoreLinks(jsonData);
  //     };
  //     reader.readAsBinaryString(file);
  //   }
  // // console.log(storeLinks)
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const uploadLink = async (event) => {
    event.preventDefault();
    // console.log(storeLinks)
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading addMemberBtn"></span>`;
    const formData = new FormData();
    formData.append("admin_id", admin_id);
    formData.append("storeFile", storeFile);
    console.log(storeFile);
    console.log(formData);
    var BASE_URL = SERVER_URL + `/api/admin/upload-links-api`;
    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
      body: formData,
      // body: JSON.stringify({
      //   admin_id: admin_id, // To validate token
      //   file: storeFile,
      // }),
    });
    const data = await res.json();
    if (res.ok) {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.SUCCESS,
        });
      }, 1000);
      // window.alert(data.message + "Message Coming");
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Upload`;
      }, 500);
    } else {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.ERROR,
        });
      }, 1000);
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Upload`;
      }, 500);
      // document.getElementById("reset").reset();
    }
  };
  const handleSampleCsvDownload = () => {
    const csvData = [
      ["diamond_id", "url", "supplier_id"], // Headers
      // Add more sample data rows if needed
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "sample.csv");
  };
  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Upload Links</h4>
              <form
                className="forms-sample"
                autoComplete="off"
                encType="multipart/form-data"
                onSubmit={uploadLink}
              >
                <div className="row manageTablesOptions">
                  <div className="form-group">
                    <label>
                      Excel or CSV file *{" "}
                      <span style={{ fontSize: "11px", fontWeight: "600" }}>
                        (Note: Excel/CSV file must contain "diamond_id", "url" &
                        "supplier_id" in headers)
                      </span>{" "}
                      <span>
                        <button
                          type="button"
                          className="btn excelBtn btn-sm btn-info btn-icon-text"
                          onClick={handleSampleCsvDownload}
                        >
                          Sample CSV
                          <i
                            className="typcn typcn-download btn-icon-append"
                            style={{ height: "4px", width: "4px" }}
                          ></i>{" "}
                        </button>
                      </span>
                    </label>
                    <input
                      type="file"
                      name="file"
                      ref={fileInputRef}
                      accept=".xlsx, .csv"
                      onChange={handleFileData}
                      className="file-upload-default"
                    />
                    <div className="input-group col-xs-12">
                      <input
                        type="text"
                        className="form-control file-upload-info"
                        readOnly
                        style={{ cursor: "pointer" }}
                        onChange={handleFileData}
                        // placeholder="Upload Excel/CSV"
                        placeholder={
                          storeFile ? storeFile.name : "Upload Excel/CSV"
                        }
                        onClick={handleUploadButtonClick}
                      />
                      <span className="input-group-append">
                        <button
                          className="file-upload-browse btn btn-primary"
                          type="button"
                          onClick={handleUploadButtonClick}
                        >
                          Attach
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2 px-5 py-3"
                    id="loginBtn"
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadLinks;
