import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";

const VerificationPageAdmin = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();

  let state = true;
  const [check, setCheck] = useState(false);
  const [error, setError] = useState(false);
  const verify = async () => {
    if (params.access_token.length < 32) {
      alert.show(`Token not valid!`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      return false;
    }
    var BASE_URL = SERVER_URL + `/api/admin/verify/${params.access_token}`;
    const res = await fetch(`${BASE_URL}`);
    const data = await res.json();
    if (res.ok) {
      setCheck(true);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      document.getElementById("err").innerHTML = `<span>${data.message}</span>`;
      setTimeout(() => {
        // navigate("/");
      }, 4000);
    } else {
      setError(true);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      document.getElementById("err").innerHTML = `<span>${data.message}</span>`;
      setTimeout(() => {
        // navigate("/");
      }, 4000);
    }
  };

  useEffect(() => {
    if (state) {
      verify();
      state = false;
    }
  }, []);
  return (
    <>
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper d-flex align-items-center auth px-0">
            <div class="row w-100 mx-0">
              <div class="col-lg-4 mx-auto">
                <div class="auth-form-light text-center py-5 px-4 px-sm-5">
                  <h4>Verification Status</h4>

                  {check && (
                    <>
                      <div class="brand-logo">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#31B404"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          style={{ marginRight: "8px", minMidth: "24px" }}
                        >
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                      </div>
                    </>
                  )}
                  {error && (
                    <>
                      <div class="brand-logo">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FF0040"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          style={{ marginRight: "8px", minMidth: "24px" }}
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12" y2="16"></line>
                        </svg>
                      </div>
                    </>
                  )}
                  <h6 class="font-weight-heavy" id="err"></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationPageAdmin;
