import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { positions, types } from "react-alert";
import { useAlert } from "react-alert";

const DataTable = ({ refreshVal }) => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row
  const [pageSize, setPageSize] = useState(25);
  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [diamondId, setDiamondId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [url, setUrl] = useState("");
  const totalPages = Math.ceil(totalRecords / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math?.min(startItem + data?.length - 1, totalRecords);
  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-trn-data/${id}?page=${currentPage}&pageSize=${pageSize}&diamond_id=${diamondId}&supplier_id=${supplierId}&url=${url}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });

    if (res.ok) {
      const result = await res.json();
      setData(result.data);
      setTotalRecords(result.totalCount);
      gridRef.current.api.hideOverlay();
    } else {
    }
    // setData(result.data);
    // Cache the data
    // localStorage.setItem("cachedData", JSON.stringify(updatedData));
  };

  let check = true;
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(false);
  useEffect(() => {
    if (check) {
      // Show the loading overlay here when either diamondId or supplierId changes
      if (loadingOverlayVisible) {
        gridRef.current.api.showLoadingOverlay();
      }

      getData();
      setLoadingOverlayVisible(true); // Set the flag to true after the first API call
    }
    check = false;
  }, [currentPage, pageSize, diamondId, supplierId, url]);

  // Add a function to handle page change
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    gridRef.current.api.showLoadingOverlay();
  };

  // Add a function to handle page size change
  const onPageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    gridRef.current.api.showLoadingOverlay();
    setCurrentPage(1); // Reset to the first page when page size changes
  };
  // const getData = async () => {
  //   const id = localStorage.getItem("adminId")?.replace(/"/g, "");
  //   let BASE_URL = SERVER_URL + `/api/admin/get-trn-data/${id}`;
  //   // Check if cached data exists
  //   // const cachedData = localStorage.getItem("cachedData");
  //   // if (cachedData) {
  //     // setData(JSON.parse(cachedData));
  //     // // return;
  //   // }
  //   const res = await fetch(BASE_URL, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${JSON.parse(
  //         localStorage.getItem("logged_res")
  //       )}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const result = await res.json();
  //   const updatedData = result.data?.map((row, index) => {
  //     return {
  //       ...row,
  //       index: (currentPage - 1) * Object.keys(result).length + index + 1,
  //     };
  //   });
  //   setData(updatedData);
  //   gridRef.current.api.hideOverlay();
  //   // setData(result.data);
  //   // Cache the data
  //   // localStorage.setItem("cachedData", JSON.stringify(updatedData));
  // };

  // useEffect(() => {
  //   getData();
  // // }, [setData]);
  // }, []);

  useEffect(() => {
    if (refreshVal === true) {
      gridRef.current.api.showLoadingOverlay();
      getData();
    }
  }, [refreshVal]);

  const onGridReady = useCallback((params) => {
    params.api.showLoadingOverlay();
  });
  // ========================================[For Api and Data set End]===================================================

  const numberToColor = (val) => {
    if (val === "In Progress") {
      return "badge-info";
    } else if (val === "Success") {
      return "badge-success";
    } else if (val === "Pending") {
      return "badge-warning";
    } else if (val === "Error") {
      return "badge-danger";
    } else if (val === "Unprocessed") {
      return "badge-secondary"; // Secondary badge for Unprocessed status
    } else {
      return "badge-primary";
    }
  };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);
  const alert = useAlert();

  //  =======================================[For getting Id for delete]======================================
  const buttonClicked = (cellValue) => {
    const confirmation = window.confirm(
      "Are you sure? You want to delete this data!"
    );
    if (confirmation) {
      console.log(`${cellValue} Delete This Id`);
    }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };
  //  =======================================[For getting Id for delete]======================================

  // Col Defs For Sample Pending Data Start===============================================
  // =========================================[For Diamond trn_log Data]============================================

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "S.No",
      // field: "index",
      valueGetter: (params) => params.node.rowIndex + 1,
      // width: 150,
      // // For selection
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      // showDisabledCheckboxes: true,
      minWidth: 205,
    },
    { headerName: "Diamond Id", field: "diamond_id", minWidth: 250 },
    { headerName: "Supplier Id", field: "supplier_id", minWidth: 205 },
    { headerName: "URL", field: "url", minWidth: 485 },
    // {
    //   headerName: "Status",
    //   field: "status",
    //   minWidth: 350,
    //   cellRenderer: (params) => (
    //     <label className={`badge ${numberToColor(params.value)}`}>
    //       {params.value}
    //     </label>
    //   ),
    //   //   cellStyle: cellStyle,
    // },
    {
      headerName: "Status",
      // field: "status",
      minWidth: 205,
      valueGetter: (params) => {
        const originalStatus = params.data.status;
        // const updatedStatus =
        //   originalStatus === "Pending" && params.data.process_flag === 0
        //     ? "In Progress"
        //     : originalStatus;
        const updatedStatus =
          originalStatus === "Pending" && params.data.process_flag === 0
            ? "In Progress"
            : params.data.process_count === 0
            ? "Unprocessed"
            : originalStatus;
        return updatedStatus;
      },
      cellRenderer: (params) => (
        <label
          className={`badge ${numberToColor(params.value)}`}
          style={{ width: "100px" }}
        >
          {params.value}
        </label>
      ),
      //   cellStyle: cellStyle,
    },
    {
      headerName: "Status Code",
      field: "status_code",
      cellRenderer: (params) => (params.value !== null ? params.value : "-"),
      minWidth: 205,
    },
  ]);

  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return sel.data.id;
  });
  // console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      // debounceMs: 100,
    },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  // Example load data from sever
  // useEffect(() => {
  //   fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //     .then((result) => result.json())

  //     .then((rowData) => setRowData(rowData));
  // }, []);

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  // window.addEventListener("error", (e) => {
  //   // eslint-disable-next-line no-console
  //   e.preventDefault();
  //   console.log(e);
  // });
  const [error, setError] = useState(null);

  useEffect(() => {
    window.addEventListener("error", handleGlobalError);
    return () => {
      window.removeEventListener("error", handleGlobalError);
    };
  }, []);

  const handleGlobalError = (event) => {
    event.preventDefault();
    setError(event.error);
  };

  if (error) {
    // You can log the error to the console if desired
    console.error(error);

    // Render a fallback UI or a custom error message
    // return <div>Oops! Something went wrong.</div>;
  }
  return (
    <div>
      <div className="card-body">
        <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
          <h4 className="table_title card-title mb-1">
            Manage Table {refreshVal}
          </h4>
          <div className="manageTablesOptions">
            {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}
            <select
              className="dropdown setpageSelection show form-control"
              name="pagination"
              style={{
                padding: ".5rem .75rem",
                borderRadius: "4px",
              }}
              value={pageSize}
              onChange={onPageSizeChange}
            >
              <option value={25}>show 25 rows</option>
              <option value={50}>show 50 rows</option>
              <option value={100}>show 100 rows</option>
              <option value={250}>show 250 rows</option>
            </select>
            {/* <select
              className="dropdown setpageSelection show form-control"
              name="pagination"
              style={{
                padding: ".5rem .75rem",
                borderRadius: "4px",
              }}
              onChange={(event) => setPagination(event)}
            >
              <option value={10}>show 10 rows</option>
              <option value={20}>show 20 rows</option>
              <option value={25}>show 25 rows</option>
              <option value={50}>show 50 rows</option>
            </select> */}
            <div>
              <button
                type="button"
                className="btn excelBtn btn-sm btn-info btn-icon-text"
                onClick={onBtnExport}
              >
                Excel
                <i className="typcn typcn-download btn-icon-append"></i>
              </button>
            </div>
          </div>
        </div>
        {/* <div className="pagination-controls">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage}</span>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage * pageSize >= totalRecords}
          >
            Next
          </button>
          <select
            className="page-size-select"
            value={pageSize}
            onChange={onPageSizeChange}
          >
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
          </select>
        </div> */}
        <h4 className="table_title card-title mb-1">Filter</h4>
        <div>
          <form className="forms-sample" autoComplete="off">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="diamondId">Diamond ID:</label>
                  <input
                    type="text"
                    className="form-control"
                    // id="diamondId"
                    value={diamondId}
                    onChange={(e) => setDiamondId(e.target.value)}
                    placeholder="Diamond ID"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="supplierId">Supplier ID:</label>
                  <input
                    type="text"
                    className="form-control"
                    // id="supplierId"
                    value={supplierId}
                    onChange={(e) => setSupplierId(e.target.value)}
                    placeholder="Supplier ID"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="url">URL:</label>
                  <input
                    type="text"
                    className="form-control"
                    // id="url"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="URL"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
        <div className="ag-theme-alpine" style={{ width: "100%", height: 680 }}>
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={data} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            // paginationPageSize={paginationRow}
            suppressExcelExport={true}
            sortingOrder={sortingOrder}
            enableCellTextSelection={true} // For cell data selection
            rowSelection={"multiple"} // For row selection
            suppressRowClickSelection={true} // For row selection
            onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
            rowHeight={50}
            onGridReady={onGridReady}
            overlayLoadingTemplate={
              '<span className="ag-overlay-loading-center">Please wait while your records are loading...</span>'
            }
            // pagination={true}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <div
              id="order-listing_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row">
                <div className="col-sm-12 col-md-5">
                  <div
                    className="dataTables_info"
                    id="order-listing_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {startItem} to {endItem} of {totalRecords} entries{" "}
                    {/* Replace "10" with the number of items per page */}
                  </div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="order-listing_paginate"
                  >
                    <ul className="pagination">
                      {/* Render the "Previous" button */}
                      <li
                        className={`paginate_button page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        id="order-listing_previous"
                        // onClick={() => handlePageChange(currentPage - 1)}
                        onClick={() => {
                          if (currentPage !== 1) {
                            onPageChange(1);
                          }
                        }}
                        disabled={currentPage === 1}
                      >
                        <a
                          href="#"
                          aria-controls="order-listing"
                          data-dt-idx="0"
                          tabIndex="0"
                          className="page-link"
                        >
                          <i
                            className="fa fa-angle-double-left"
                            style={{
                              height: "4px",
                              width: "4px",
                            }}
                          ></i>
                        </a>
                      </li>
                      <li
                        className={`paginate_button page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        id="order-listing_previous"
                        // onClick={() => handlePageChange(currentPage - 1)}
                        onClick={() => {
                          if (currentPage !== 1) {
                            onPageChange(currentPage - 1);
                          }
                        }}
                        disabled={currentPage === 1}
                      >
                        <a
                          href="#"
                          aria-controls="order-listing"
                          data-dt-idx="0"
                          tabIndex="0"
                          className="page-link"
                        >
                          <i
                            className="fa fa-chevron-left"
                            style={{
                              height: "4px",
                              width: "4px",
                            }}
                          ></i>
                        </a>
                      </li>

                      {/* Render the current page number */}
                      <li className="paginate_button page-item active">
                        <a
                          href="#"
                          aria-controls="order-listing"
                          data-dt-idx="1"
                          tabIndex="0"
                          className="page-link"
                        >
                          Page {currentPage} of {totalPages}
                        </a>
                      </li>

                      {/* Render the "Next" button */}
                      <li
                        className={`paginate_button page-item ${
                          currentPage * pageSize >= totalRecords
                            ? "disabled"
                            : ""
                        }`}
                        id="order-listing_next"
                        // onClick={() => handlePageChange(currentPage + 1)}
                        onClick={() => {
                          if (currentPage * pageSize < totalRecords) {
                            onPageChange(currentPage + 1);
                          }
                        }}
                        // disabled={currentPage * pageSize >= totalRecords}
                      >
                        <a
                          href="#"
                          aria-controls="order-listing"
                          data-dt-idx="2"
                          tabIndex="0"
                          className="page-link"
                        >
                          <i
                            className="fa fa-chevron-right"
                            style={{ height: "4px", width: "4px" }}
                          ></i>
                        </a>
                      </li>
                      <li
                        className={`paginate_button page-item ${
                          currentPage * pageSize >= totalRecords
                            ? "disabled"
                            : ""
                        }`}
                        id="order-listing_next"
                        // onClick={() => handlePageChange(currentPage + 1)}
                        onClick={() => {
                          if (currentPage !== totalPages) {
                            onPageChange(totalPages);
                          }
                        }}
                        // disabled={currentPage * pageSize >= totalRecords}
                      >
                        <a
                          href="#"
                          aria-controls="order-listing"
                          data-dt-idx="2"
                          tabIndex="0"
                          className="page-link"
                        >
                          <i
                            className="fa fa-angle-double-right"
                            style={{ height: "4px", width: "4px" }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
