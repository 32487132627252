import React, { useState, useEffect, useRef } from "react";
import adminImg from "../../../assets/images/adminImg.png";
import AdminUpdate from "./AdminUpdate";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import ChangePassAdmin from "./ChangePassAdmin";
function AdminProfile() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();

  // console.log(JSON.parse(localStorage.getItem("logged_res")));
  const [store, setStore] = useState([]);
  const getAdminData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL = SERVER_URL + `/api/admin/get/${id}`;
    let result = await fetch(BASE_URL, {
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
    });
    result = await result.json();
    setStore(result.data);
  };
  const storeFlag = localStorage.getItem("Update");
  // console.log(storeFlag);
  if (storeFlag === "true") {
    getAdminData();
  }
  useEffect(() => {
    getAdminData();
  }, []);

  const buttonClick = () => {
    alert.show(`Check Alert`, {
      position: positions.TOP_CENTER,
      type: types.ERROR,
    });
  };
  return (
    <>
      <div className="content-wrapper">
        {/* <div className="row  mt-3">
          <div className="col-xl-12 d-flex grid-margin stretch-card">
            <div className="card">
              <div className="card-body"></div>
            </div>
          </div>
        </div> */}
        <div className="row mt-3">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-wrap justify-content-between">
                  <h4 className="card-title mb-3">Admin Profile</h4>{" "}
                  {/* <button onClick={buttonClick} className="btn btn-sm btn-dark">
                    Check Alert
                  </button> */}
                </div>

                <div className="row">
                  <div className="col-lg-4">
                    <div className="border-bottom text-center pb-4">
                      {/* <img
                        src={adminImg}
                        alt="profile"
                        className="img-lg rounded-circle mb-3"
                      /> */}
                      <img
                        src={SERVER_URL + "/profile/" + store?.image}
                        alt="profile"
                        className="img-lg rounded-circle mb-3"
                      />
                      <div className="mb-3">
                        <h3>{store.name}</h3>
                        {/* <div className="d-flex align-items-center justify-content-center">
                          <h5 className="mb-0 me-2 mt-1">{store.email}</h5>
                        </div> */}
                      </div>
                      {/* <p className="w-75 mx-auto mb-1">{store.phone}</p> */}
                      <p className="w-75 mx-auto mb-1 text-primary">
                        <b>
                          {store.role == 1
                            ? "Super Admin"
                            : store.role == 2
                            ? "Admin"
                            : "Memeber"}
                        </b>
                      </p>
                    </div>
                    <div className="py-4">
                      <p className="clearfix">
                        <span className="float-left">Name</span>
                        <span className="float-right">
                          <b>{store.name}</b>
                        </span>
                      </p>
                      <p className="clearfix">
                        <span className="float-left">Phone</span>
                        <span className="float-right">
                          <b>{store.phone}</b>
                        </span>
                      </p>
                      <p className="clearfix">
                        <span className="float-left">Email</span>
                        <span className="float-right">
                          <b>{store.email}</b>
                        </span>
                      </p>
                      <p className="clearfix">
                        <span className="float-left">Last Login</span>
                        <span className="float-right">
                          <b>
                            {store.last_login == null
                              ? "00-00-0000"
                              : store.last_login}
                          </b>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="mt-1 py-2 border-top border-bottom">
                      <ul className="nav profile-navbar">
                        {/* <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#tab_default_1"
                          >
                            <i className="typcn typcn-user-outline"></i>
                            Info
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#tab_default_1"
                          >
                            <i className="typcn typcn-edit"></i>
                            Update
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#tab_default_2"
                          >
                            <i className="typcn typcn-key-outline"></i>
                            Change Password
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      {/* <div className="tab-pane active" id="tab_default_0">
                        <div className="profile-feed">
                          <div className="row">
                            <div className="col-md-4">
                              <p className="clearfix">Name</p>
                            </div>
                            <div className="col-md-8">
                              <p className="clearfix">
                                {store.name}
                          
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="clearfix">Email</p>
                            </div>
                            <div className="col-md-8">
                              <p className="clearfix">
                                {store.email}
                                
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="clearfix">Phone</p>
                            </div>
                            <div className="col-md-8">
                              <p className="clearfix">
                                {store.phone}
                               
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="clearfix">Role</p>
                            </div>
                            <div className="col-md-8">
                              <p className="clearfix">
                                {store.role}
                                
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="clearfix">Last Login</p>
                            </div>
                            <div className="col-md-8">
                              <p className="clearfix">
                                {store.last_login}
                               
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="tab-pane active" id="tab_default_1">
                        <AdminUpdate
                          adName={store.name}
                          adPhone={store.phone}
                          adImage={store.image}
                          adEmail={store.email}
                        />
                      </div>
                      <div className="tab-pane" id="tab_default_2">
                        <ChangePassAdmin />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminProfile;
