import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import * as XLSX from "xlsx";

import { positions, types } from "react-alert";
import { useAlert } from "react-alert";

const SampleTryTable = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row

  // ========================================[For Api and Data set Start]===================================================

  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [diamondId, setDiamondId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-trn-data-search?status=${status}&page=${currentPage}&diamond_id=${diamondId}`;

    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();

    // setData(result.data);
    const updatedData = result.data?.map((row, index) => {
      return {
        ...row,
        index: (currentPage - 1) * Object.keys(result).length + index + 1,
      };
    });
    setData(updatedData);
  };

  useEffect(() => {
    getData();
  }, [status, diamondId, currentPage]);

  // ========================================[For Api and Data set End]===================================================

  const numberToColor = (val) => {
    if (val === "Idle") {
      return "badge-info";
    } else if (val === "Active") {
      return "badge-success";
    } else if (val === "Pending") {
      return "badge-warning";
    } else if (val === "Blocked") {
      return "badge-danger";
    } else {
      return "badge-primary";
    }
  };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);
  const alert = useAlert();

  //  =======================================[For getting Id for delete Start]======================================
  const buttonClicked = (cellValue) => {
    const confirmation = window.confirm(
      "Are you sure? You want to delete this data!"
    );
    if (confirmation) {
      console.log(`${cellValue} Delete This Id`);
    }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };

  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return sel.data.id;
  });
  console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      debounceMs: 100,
    },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const [speed, setSpeed] = useState(1);

  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h4 className="table_title card-title mb-1">Manage Table</h4>
                <div className="manageTablesOptions">
                  {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div>
                  <select
                    className="dropdown setpageSelection show form-control"
                    name="pagination"
                    style={{
                      padding: ".5rem .75rem",
                      borderRadius: "4px",
                    }}
                    onChange={(event) => setPagination(event)}
                  >
                    <option value={10}>show 10 rows</option>
                    <option value={20}>show 20 rows</option>
                    <option value={25}>show 25 rows</option>
                    <option value={50}>show 50 rows</option>
                  </select> */}
                  {/* For Import */}
                  {/* <div className="form-group">
                    <input
                      type="file"
                      name="image"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      class="file-upload-default"
                    />
                    <div class="input-group col-xs-12">
                      <span class="input-group-append">
                        <button
                          class="btn excelBtn btn-sm btn-info btn-icon-text"
                          type="button"
                          onClick={handleUploadButtonClick}
                        >
                          Import
                          <i className="typcn typcn-upload btn-icon-append"></i>
                        </button>
                      </span>
                    </div>
                  </div> */}
                  {/* <div>
                    <button
                      type="button"
                      className="btn excelBtn btn-sm btn-info btn-icon-text"
                      onClick={onBtnExport}
                    >
                      Excel
                      <i className="typcn typcn-download btn-icon-append"></i>
                    </button>
                  </div> */}
                </div>
              </div>

              {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
              {/* <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 680 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={errorFilter} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  rowHeight={50}
                  pagination={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div> */}
              <div>
                <div class="table-responsive">
                  <div
                    id="order-listing_wrapper"
                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div
                          class="dataTables_length"
                          id="order-listing_length"
                        >
                          <label>
                            Show{" "}
                            <select
                              name="order-listing_length"
                              aria-controls="order-listing"
                              class="custom-select custom-select-sm form-control"
                            >
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="-1">All</option>
                            </select>{" "}
                            entries
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="order-listing_filter"
                          class="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              class="form-control"
                              placeholder="Search"
                              aria-controls="order-listing"
                              onChange={(e) => setStatus(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="order-listing_filter"
                          class="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              class="form-control"
                              placeholder="Search"
                              aria-controls="order-listing"
                              onChange={(e) => setDiamondId(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <table
                          id="order-listing"
                          class="table dataTable no-footer"
                          role="grid"
                          aria-describedby="order-listing_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                class="sorting_asc"
                                tabindex="0"
                                aria-controls="order-listing"
                                rowspan="1"
                                colspan="1"
                                aria-sort="ascending"
                                aria-label="Order #: activate to sort column descending"
                                style={{ width: "115.922px" }}
                              >
                                S.no.
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="order-listing"
                                rowspan="1"
                                colspan="1"
                                aria-label="Purchased On: activate to sort column ascending"
                                style={{ width: "192.375px" }}
                              >
                                Diamond Id
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="order-listing"
                                rowspan="1"
                                colspan="1"
                                aria-label="Customer: activate to sort column ascending"
                                style={{ width: "142.719px" }}
                              >
                                Supplier Id
                              </th>

                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="order-listing"
                                rowspan="1"
                                colspan="1"
                                aria-label="Base Price: activate to sort column ascending"
                                style={{ width: "153.438px" }}
                              >
                                URL
                              </th>

                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="order-listing"
                                rowspan="1"
                                colspan="1"
                                aria-label="Status: activate to sort column ascending"
                                style={{ width: "113.672px" }}
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.length > 0 ? (
                              data?.map((items, index) => {
                                return (
                                  <>
                                    <tr role="row" class="odd">
                                      <td class="sorting_1">{index + 1}</td>
                                      <td>{items.diamond_id}</td>
                                      <td>{items.supplier_id}</td>
                                      <td>{items.url}</td>
                                      <td><label className={`badge
                                          ${items.status === "Success"
                                            ? "badge-success"
                                            : items.status === "Error"
                                            ? "badge-danger"
                                            : items.status === "Pending"
                                            ? "badge-warning"
                                            : items.status === "Pending" &&
                                              items.process_flag === 1
                                            ? "badge-info"
                                            : "badge-primary"
                                          }
                                        `}>
                                        {items.status}
                                        </label></td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <div>No Data Found</div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-5">
                        <div
                          class="dataTables_info"
                          id="order-listing_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 10 entries
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-7">
                        <div
                          class="dataTables_paginate paging_simple_numbers"
                          id="order-listing_paginate"
                        >
                          <ul class="pagination">
                            <li
                              class="paginate_button page-item previous disabled"
                              id="order-listing_previous"
                              onClick={() => setCurrentPage(currentPage - 1)}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="0"
                                tabindex="0"
                                class="page-link"
                              >
                                Previous
                              </a>
                            </li>
                            <li class="paginate_button page-item active">
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="1"
                                tabindex="0"
                                class="page-link"
                              >
                                1
                              </a>
                            </li>
                            <li
                              class="paginate_button page-item next disabled"
                              id="order-listing_next"
                              onClick={() => setCurrentPage(currentPage + 1)}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="2"
                                tabindex="0"
                                class="page-link"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleTryTable;
