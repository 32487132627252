import React, { useState, useEffect, useRef } from "react";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";

function AddClient() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();
  const admin_id = localStorage.getItem("adminId")?.replace(/"/g, "");
  const [showPassowrd, setShowPassowrd] = useState(false);
  const [for_email, setForEmail] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm
  );
  const [access_key, setAccess_Key] = useState("");
  const accessToken = () => {
    // declare all characters
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let token = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 32; i++) {
      token += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setAccess_Key(token);
  };

  useEffect(() => {
    accessToken();
  }, []);

  const addClient = async (event) => {
    event.preventDefault();
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading addMemberBtn"></span>`;

    var BASE_URL = SERVER_URL + `/api/users/register`;
    const name = event.target.name.value;
    const email = event.target.email.value;
    const access_key = event.target.access_key.value;
    // const role = event.target.role.value;
    // const password = event.target.password.value;
    // const confirm_password = event.target.confirmPassword.value;

    if (email && !email.match(for_email)) {
      window.alert(`Enter valid Email`);
      alert.show(`Enter valid Email`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      document.getElementById("loginBtn").innerHTML = `Add Client`;
      return false;
    }
    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        admin_id: admin_id, // To validate token
        username: name,
        email: email,
        access_key: access_key,
        // phone: phone,
        // role: role,
        // password: password,
        // confirm_password: confirm_password,
      }),
    });
    const data = await res.json();
    if (res.ok) {
      window.alert(`${data.message}`);
      accessToken();
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      // window.alert(data.message + "Message Coming");
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Add Client`;
      }, 2000);
    } else {
      window.alert(`${data.message}`);
      accessToken();
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Add Client`;
      }, 2000);
      // document.getElementById("reset").reset();
    }
  };
  function hideShowPassword() {
    let password = document.getElementById("password");
    let confirm_password = document.getElementById("confirm_password");
    if (showPassowrd) {
      password.type = "password";
      confirm_password.type = "password";
      setShowPassowrd(false);
    } else {
      password.type = "text";
      confirm_password.type = "text";
      setShowPassowrd(true);
    }
  }
  const buttonClick = () => {
    alert.show(`Just Checking Working`, {
      position: positions.TOP_CENTER,
      type: types.SUCCESS,
      onOpen: () => {
        console.log("hey");
      }, // callback that will be executed after this alert open
      onClose: () => {
        console.log("closed");
      }, // callback that will be executed after this alert is removed
    });
    console.log("Clicked");
  };

  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Client</h4>

              {/* <div>
                <button className="btn btn-dark" onClick={buttonClick}>
                  Click check
                </button>
              </div> */}

              <form
                className="forms-sample"
                autoComplete="off"
                onSubmit={addClient}
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputUsername1">Username</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Username"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputAccessKey1">Access Key</label>
                      <input
                        type="text"
                        name="access_key"
                        className="form-control"
                        id="exampleInputAccessKey1"
                        value={access_key}
                        readOnly
                        placeholder="Access Key"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="exampleSelectGender">Member's Type</label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="role"
                      >
                     
                        <option selected disabled>
                          Select Type
                        </option>
                        <option value="2">Sub-Admin</option>
                        <option value="3">Member</option>
                      </select>
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPhone1"
                        placeholder="Phone"
                        name="phone"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="exampleInputConfirmPassword1">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        id="confirm_password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group mb-3">
                      <div className="form-check form-check-flat form-check-primary">
                        <label className="form-check-label">
                          Show Password{" "}
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => hideShowPassword()}
                          />
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2 px-5 py-3"
                    id="loginBtn"
                  >
                    Add Client
                  </button>
                </div>
                {/* <div className="form-group">
                    <button
                      id="loginBtn"
                      type="submit"
                      className="form-control btn btn-primary rounded signInBtn px-3"
                    >
                      Sign In
                    </button>
                  </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddClient;
