import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import ImgNotFound from "../../../assets/images/ImgNotFound.png";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";

const SuccesTable = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const navigate = useNavigate();
  // const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row

  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [imgVal, setImgVal] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [diamondId, setDiamondId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  // const [url, setUrl] = useState("");
  const totalPages = Math.ceil(totalRecords / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math?.min(startItem + data?.length - 1, totalRecords);
  // const [cachedData, setCachedData] = useState(null);
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    // let BASE_URL = SERVER_URL + `/api/admin/get-trn-data-success/${id}`;
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-trn-data-success/${id}?page=${currentPage}&pageSize=${pageSize}&diamond_id=${diamondId}&supplier_id=${supplierId}`;
    // // Check if cached data exists
    // const cachedData = localStorage.getItem("cachedSuccessData");
    // if (cachedData) {
    // setData(JSON.parse(cachedData));
    //   //   return;
    // }
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    if (res.ok) {
      setData(result.data);
      setTotalRecords(result.totalCount);
      gridRef.current.api.hideOverlay();
    } else {
    }

    gridRef.current.api.hideOverlay();
    // setData(result.data);
    // // Cache the data
    // localStorage.setItem("cachedSuccessData", JSON.stringify(updatedData));
  };
  // useEffect(() => {
  //   getData();
  //   // }, [setData]);
  // }, []);
  let check = true;
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(false);
  useEffect(() => {
    if (check) {
      // Show the loading overlay here when either diamondId or supplierId changes
      if (loadingOverlayVisible) {
        gridRef.current.api.showLoadingOverlay();
      }
  
      getData();
      setLoadingOverlayVisible(true); // Set the flag to true after the first API call
    }
    check = false;
  }, [currentPage, pageSize, diamondId, supplierId]); // Include diamondId and supplierId in the dependencies
  
  // Add a function to handle page change
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    gridRef.current.api.showLoadingOverlay();
  };

  // Add a function to handle page size change
  const onPageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    gridRef.current.api.showLoadingOverlay();
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  const onGridReady = useCallback((params) => {
    params.api.showLoadingOverlay();
  });
  // =========================[For Sample Data Stored Combining trn_main and trn_log End]==============================

  // ========================================[For Api and Data set End]===================================================
  // ========================================[For Filter Success Start]===================================================
  // const [successFilter, setSuccessFilter] = useState([]);
  // function filterFun() {
  //   // data?.map(() => {
  //   //   const datas = data?.filter(
  //   //     (d) => d.status === "Success"
  //   //     // console.log(blog.category === activeCategory)
  //   //   );
  //   //   const updatedData = datas?.map((row, index) => {
  //   //     return {
  //   //       ...row,
  //   //       index: (currentPage - 1) * Object.keys(datas).length + index + 1,
  //   //     };
  //   //   });
  //   //   setSuccessFilter(updatedData);
  //   // });
  //   data?.map(() => {
  //     const uniqueDiamondIds = [...new Set(data?.map((d) => d.diamond_id))];
  //     const datas = uniqueDiamondIds
  //       .map((diamondId) => {
  //         const successData = data
  //           ?.filter(
  //             (d) => d.diamond_id === diamondId && d.status === "Success"
  //           )
  //           .sort((a, b) => new Date(b.time_stamp) - new Date(a.time_stamp));
  //         return successData[0];
  //       })
  //       .filter(Boolean);
  //     const updatedData = datas?.map((row, index) => {
  //       return {
  //         ...row,
  //         index: (currentPage - 1) * datas.length + index + 1,
  //       };
  //     });
  //     setSuccessFilter(updatedData);
  //   });
  // }
  // useEffect(() => {
  //   filterFun();
  // }, [data]);
  // ========================================[For Filter Success End]===================================================

  // ========================================[For Generate Link Btn Start]===================================================
  const [storeDiamondId, setStoreDiamondId] = useState("");
  const admin_id = localStorage.getItem("adminId")?.replace(/"/g, "");
  const generateUrl = async (diamond_id, supplier_id) => {
    var BASE_URL = SERVER_URL + `/api/admin/generate-url`;
    if (diamond_id.length > 1) {
      document.getElementById(
        "loginBtn"
      ).innerHTML = `<span class="load loading generateSelectedBtn"></span>`;
    }
    let result = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        admin_id: admin_id, // To validate token
        // storeDiamondId: storeDiamondId,
        storeDiamondId: diamond_id,
      }),
    });

    const data = await result.json();
    if (result.ok) {
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      // window.alert(`${data.message}`);
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.SUCCESS,
        });
        setRmvBtn(false);
      }, 1000);

      // window.alert(data.message + "Message Coming");
      if (diamond_id.length > 1) {
        document.getElementById("loginBtn").innerHTML = `Generate Selected`;
      }
      getData();
    } else {
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.ERROR,
        });
      }, 1000);
      // window.alert(`${data.message}`);

      // setTimeout(() => {
      if (diamond_id.length > 1) {
        document.getElementById("loginBtn").innerHTML = `Generate Selected`;
      }
      // }, 2000);
      // document.getElementById("reset").reset();
      getData();
    }
  };
  // ========================================[For Generate Link Btn End]===================================================

  const numberToColor = (val) => {
    if (val === "Idle") {
      return "badge-info";
    } else if (val === "Success") {
      return "badge-success";
    } else if (val === "Pending") {
      return "badge-warning";
    } else if (val === "Blocked") {
      return "badge-danger";
    } else {
      return "badge-primary";
    }
  };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);
  const alert = useAlert();

  //  =======================================[For getting Id for delete]======================================
  const buttonClicked = (cellValue) => {
    const confirmation = window.confirm(
      "Are you sure? You want to delete this data!"
    );
    if (confirmation) {
      console.log(`${cellValue} Delete This Id`);
      const singleFilteredData = data.filter((row) => {
        return row.id !== cellValue;
      });
      setData(singleFilteredData);
      console.log(data);
    }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };

  const generateUrlBtn = async (cellValue, supplier_id) => {
    gridRef.current.api.showLoadingOverlay();
    setStoreDiamondId(cellValue);
    const confirmation = window.confirm(
      "Are you sure? You want to generate link"
    );
    if (confirmation) {
      generateUrl(cellValue, supplier_id);
      // console.log(`${cellValue} Generate link for this diamond_id`);
    }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };

  // const copyLinkBtn = async (cellValue, token, supplier_id) => {
  const copyLinkBtn = async (cellValue, supplier_id) => {
    // const confirmation = window.confirm("Are you sure? You want to copy link");
    // if (confirmation) {
    // console.log(`${cellValue} Copy link for this diamond_id`);
    // if (!token || token === null) {
    // window.alert("First Generate URL for this Diamond Id !");
    // alert.show(`First Generate URL for this Diamond Id !`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
    // setTimeout(() => {
    //   alert.show(`First Generate URL for this Diamond Id !`, {
    //     position: positions.TOP_CENTER,
    //     type: types.ERROR,
    //   });
    // }, 500);
    // } else {
    await navigator.clipboard.writeText("");
    await navigator.clipboard.writeText(
      CLIENT_URL + "/videoplayer360/" + cellValue + "/" + supplier_id
      // + "/" +
      // token
    );
    alert.show(`Link copied`, {
      position: positions.TOP_CENTER,
      type: types.SUCCESS,
    });
    setTimeout(() => {
      alert.show(`Link copied`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
    }, 500);
    // window.alert("Link copied");
    // }
    // }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };
  // const viewLinkBtn = async (cellValue, token, supplier_id) => {
  const viewLinkBtn = async (cellValue, supplier_id) => {
    // const confirmation = window.confirm("Are you sure? You want to view link");
    // if (confirmation) {
    // console.log(`${cellValue} View link for this diamond_id`);
    // console.log(`${token} Token for diamond_id`);
    // if (!token || token === null) {
    // window.alert("First Generate URL for this Diamond Id !");
    // alert.show(`First Generate URL for this Diamond Id !`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
    // setTimeout(() => {
    //   alert.show(`First Generate URL for this Diamond Id !`, {
    //     position: positions.TOP_CENTER,
    //     type: types.ERROR,
    //   });
    // }, 500);
    // } else {
    // navigate("/videoplayer360/" + cellValue + "/" + token);
    window.open(
      CLIENT_URL + "/videoplayer360/" + cellValue + "/" + supplier_id,
      // +
      // "/" +
      // token
      "_blank"
    );
    // }
  };
  //  =======================================[For getting Id for delete]======================================

  // Col Defs For Sample Pending Data Start===============================================
  // =========================================[For Diamond trn_log Data]============================================
  const [columnDefs, setColumnDefs] = useState([
    // {
    //   headerName: "S.No",
    //   // field: "index",
    //   valueGetter: (params) => params.node.rowIndex + 1,
    //   // width: 150,
    //   minWidth: 205,
    //   // For selection
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   showDisabledCheckboxes: true,
    // },
    { headerName: "Id", field: "id"},
    { headerName: "Diamond Id", field: "diamond_id", minWidth: 150 },
    { headerName: "Supplier Id", field: "supplier_id", minWidth: 150 },
    {
      headerName: "Status",
      field: "status",
      minWidth: 305,
      cellRenderer: (params) => (
        <label
          className={`badge ${numberToColor(params.value)}`}
          style={{ width: "100px" }}
        >
          {params.value}
        </label>
      ),
      //   cellStyle: cellStyle,
    },
    // {
    //   headerName: "Video Path",
    //   field: "videopath",
    //   minWidth: 300,
    //   cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    // },
    { headerName: "URL", field: "url", minWidth: 400 },
    {
      headerName: "Diamond Image",
      field: "firstFramePreSignedURL",
      cellRenderer: (params) => (
        // <img src={`${params.value}`}/>
        // <label>{`${params.value}`}</label>
        <>
          {params.value == null ? (
            <img
              src={ImgNotFound}
              alt=''
              height="50px"
              width="50px"
              style={{ objectFit: "contain" }}
            ></img>
          ) : (
            <img
              height="50px"
              width="50px"
              alt=""
              // src={
              //   "https://rxvdoex.s3.amazonaws.com/base64JsonBrokenKafkaTrial/8054_7004/0.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAW2CF75HTDJLWMRCE%2F20230711%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230711T132220Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=6c2b014dd86d8dc9f729797de7e91e4fcb8734359734ace5161df793d5f9c65e"
              // }
              src={`${params.value}`}
              style={{ objectFit: "contain" }}
              className="dImgHover"
              onClick={() => setImgVal(`${params.value}`)}
              data-toggle="modal"
              data-target="#exampleModalCenter"
            />
          )}
        </>
      ),
    },
    {
      headerName: "Actions",
      field: "diamond_id",
      // minWidth: 400,
      minWidth: 320,

      cellRenderer: (params) => (
        <>
          {/* <button
            onClick={() =>
              generateUrlBtn([[params.value, params.data.supplier_id]])
            }
            className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
          >
            Generate
            <i className="typcn typcn-upload btn-icon-append"></i>
          </button>{" "} */}
          <button
            onClick={() =>
              viewLinkBtn(
                params.value,
                // params.data.token,
                params.data.supplier_id
              )
            }
            className="btn btn-sm btn-outline-primary btn-icon-text"
          >
            View
            <i className="fa fa-eye btn-icon-append" aria-hidden="true"></i>
          </button>{" "}
          &nbsp;
          <button
            onClick={() =>
              copyLinkBtn(
                params.value,
                // params.data.token,
                params.data.supplier_id
              )
            }
            className="btn btn-sm btn-outline-info btn-icon-text"
          >
            Copy Link
            <i className="fa fa-link btn-icon-append" aria-hidden="true"></i>
          </button>
        </>
      ),
    },
    { headerName: "Date & Time", field: "time_stamp", minWidth: 300 },
  ]);

  // Col Defs For Sample Pending Data End===============================================
  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    // return [sel.data.diamond_id];
    return [sel.data.diamond_id, sel.data.supplier_id];
  });
  // console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      // debounceMs: 100,
    },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  // Example load data from sever
  // useEffect(() => {
  //   fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //     .then((result) => result.json())

  //     .then((rowData) => setRowData(rowData));
  // }, []);

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleButtonClick = () => {
    gridRef.current.api.showLoadingOverlay();
    getData();
  };

  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h4 className="table_title card-title mb-1">
                  Manage Success Table{" "}
                  <span>
                    <button
                      className="btn btn-sm bg-white btn-icon-text border"
                      onClick={handleButtonClick}
                    >
                      Refresh
                      <i
                        className="typcn typcn-refresh"
                        style={{ height: "4px", width: "4px" }}
                      ></i>{" "}
                    </button>
                  </span>
                </h4>
                <div className="manageTablesOptions">
                  {/* <div>
                    {rmvBtn && (
                      <button
                        id="loginBtn"
                        type="button"
                        onClick={() => generateUrlBtn(storedSelected)}
                        className="btn removeAllBtn btn-sm btn-secondary btn-icon-text"
                      >
                        Generate Selected
                        <i
                          className="typcn typcn-upload btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}
                  <select
                    className="dropdown setpageSelection show form-control"
                    name="pagination"
                    style={{
                      padding: ".5rem .75rem",
                      borderRadius: "4px",
                    }}
                    value={pageSize}
                    onChange={onPageSizeChange}
                  >
                    <option value={25}>show 25 rows</option>
                    <option value={50}>show 50 rows</option>
                    <option value={100}>show 100 rows</option>
                    <option value={250}>show 250 rows</option>
                  </select>
                  {/* <select
              className="dropdown setpageSelection show form-control"
              name="pagination"
              style={{
                padding: ".5rem .75rem",
                borderRadius: "4px",
              }}
              onChange={(event) => setPagination(event)}
            >
              <option value={10}>show 10 rows</option>
              <option value={20}>show 20 rows</option>
              <option value={25}>show 25 rows</option>
              <option value={50}>show 50 rows</option>
            </select> */}
                  <div>
                    <button
                      type="button"
                      className="btn excelBtn btn-sm btn-info btn-icon-text"
                      onClick={onBtnExport}
                    >
                      Excel
                      <i className="typcn typcn-download btn-icon-append"></i>
                    </button>
                  </div>
                </div>
              </div>
              <h3 className="table_title card-title mb-1">Filter</h3>
              <div>
                <form className="forms-sample" autoComplete="off">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="diamondId">Diamond ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="diamondId"
                          value={diamondId}
                          onChange={(e) => setDiamondId(e.target.value)}
                          placeholder="Diamond ID"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="supplierId">Supplier ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="supplierId"
                          value={supplierId}
                          onChange={(e) => setSupplierId(e.target.value)}
                          placeholder="Supplier ID"
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="url">URL:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="url"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          placeholder="URL"
                        />
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>
              {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 680 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={data} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  // paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  enableCellTextSelection={true} // For cell data selection
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  rowHeight={50}
                  onGridReady={onGridReady}
                  overlayLoadingTemplate={
                    '<span className="ag-overlay-loading-center">Please wait while your records are loading...</span>'
                  }
                  // pagination={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <div
                    id="order-listing_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="order-listing_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing {startItem} to {endItem} of {totalRecords}{" "}
                          entries{" "}
                          {/* Replace "10" with the number of items per page */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="order-listing_paginate"
                        >
                          <ul className="pagination">
                            {/* Render the "Previous" button */}
                            <li
                              className={`paginate_button page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              id="order-listing_previous"
                              // onClick={() => handlePageChange(currentPage - 1)}
                              onClick={() => {
                                if (currentPage !== 1) {
                                  onPageChange(1);
                                }
                              }}
                              disabled={currentPage === 1}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="0"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-angle-double-left"
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                  }}
                                ></i>
                              </a>
                            </li>
                            <li
                              className={`paginate_button page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              id="order-listing_previous"
                              // onClick={() => handlePageChange(currentPage - 1)}
                              onClick={() => {
                                if (currentPage !== 1) {
                                  onPageChange(currentPage - 1);
                                }
                              }}
                              disabled={currentPage === 1}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="0"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-chevron-left"
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                  }}
                                ></i>
                              </a>
                            </li>

                            {/* Render the current page number */}
                            <li className="paginate_button page-item active">
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="1"
                                tabIndex="0"
                                className="page-link"
                              >
                                Page {currentPage} of {totalPages}
                              </a>
                            </li>

                            {/* Render the "Next" button */}
                            <li
                              className={`paginate_button page-item ${
                                currentPage * pageSize >= totalRecords
                                  ? "disabled"
                                  : ""
                              }`}
                              id="order-listing_next"
                              // onClick={() => handlePageChange(currentPage + 1)}
                              onClick={() => {
                                if (currentPage * pageSize < totalRecords) {
                                  onPageChange(currentPage + 1);
                                }
                              }}
                              // disabled={currentPage * pageSize >= totalRecords}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="2"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-chevron-right"
                                  style={{ height: "4px", width: "4px" }}
                                ></i>
                              </a>
                            </li>
                            <li
                              className={`paginate_button page-item ${
                                currentPage * pageSize >= totalRecords
                                  ? "disabled"
                                  : ""
                              }`}
                              id="order-listing_next"
                              // onClick={() => handlePageChange(currentPage + 1)}
                              onClick={() => {
                                if (currentPage !== totalPages) {
                                  onPageChange(totalPages);
                                }
                              }}
                              // disabled={currentPage * pageSize >= totalRecords}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="2"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-angle-double-right"
                                  style={{ height: "4px", width: "4px" }}
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {/* <div className="modal-header">
              <button
                type="button"
                className="close btnClose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div className="modal-body">
              <div
                className="form-group"
                style={{
                  textAlign: "center",
                  height: "450px",
                  margin: "-0.5rem",
                }}
              >
                <img
                  height="100%"
                  width="100%"
                  alt=""
                  src={imgVal}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccesTable;
