import React, { useEffect, useState } from "react";

export default function ThemeSetting() {
  const [bgColor, setBgColor] = useState("");
  const [themeClass, setThemeClass] = useState(".tiles.default.border");

  const triggerThemeSetting = () => {
    const themeSetting = document.getElementById("theme-settings");
    themeSetting.classList.add("open");
  };
  const closeThemeSetting = () => {
    const themeSetting = document.getElementById("theme-settings");
    themeSetting.classList.remove("open");
  };

  const changeSideBar = (a, b) => {
    const sidebarLightTheme = document.querySelector("#sidebar-light-theme");
    const sidebarDarkTheme = document.querySelector("#sidebar-dark-theme");
    document.body.classList.remove(b);
    document.body.classList.add(a);
    if (a === "sidebar-light") {
      sidebarDarkTheme.classList.remove("selected");
      sidebarLightTheme.classList.add("selected");
    } else {
      sidebarLightTheme.classList.remove("selected");
      sidebarDarkTheme.classList.add("selected");
    }
  };

  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (bgColor) {
      navbar.classList.remove(
        "navbar-success",
        "navbar-warning",
        "navbar-danger",
        "navbar-primary",
        "navbar-info",
        "navbar-dark"
      );
      navbar.classList.add(bgColor);
    } else {
      navbar.classList.remove(
        "navbar-success",
        "navbar-warning",
        "navbar-danger",
        "navbar-primary",
        "navbar-info",
        "navbar-dark"
      );
    }
  }, [bgColor]);

  useEffect(() => {
    const tilesClasses = document.getElementsByClassName("tiles");
    const navbarTheme = document.querySelector(`${themeClass}`);
    for (let i = 0; i < tilesClasses.length; i++) {
      tilesClasses[i].classList.remove("selected");
    }
    navbarTheme.classList.add("selected");
  }, [themeClass]);

  return (
    <div className="theme-setting-wrapper">
      <div id="settings-trigger" onClick={triggerThemeSetting}>
        <i className="fa fa-cog" aria-hidden="true"></i>
      </div>
      <div id="theme-settings" className="settings-panel">
        <i
          onClick={closeThemeSetting}
          className="settings-close typcn typcn-delete-outline"
        ></i>
        <p className="settings-heading">Sidebar Customization</p>
        <div
          className="sidebar-bg-options"
          id="sidebar-light-theme"
          onClick={() => changeSideBar("sidebar-light", "sidebar-dark")}
        >
          <div className="img-ss rounded-circle bg-light border mr-3"></div>
          Light
        </div>
        <div
          className="sidebar-bg-options selected"
          id="sidebar-dark-theme"
          onClick={() => changeSideBar("sidebar-dark", "sidebar-light")}
        >
          <div className="img-ss rounded-circle bg-dark border mr-3"></div>
          Dark
        </div>
        <p className="settings-heading mt-2">Top Header Customization</p>
        <div className="color-tiles mx-0 px-4">
          <div
            className="tiles success"
            onClick={() => {
              setBgColor("navbar-success");
              setThemeClass(".tiles.success");
            }}
          ></div>
          <div
            className="tiles warning"
            onClick={() => {
              setBgColor("navbar-warning");
              setThemeClass(".tiles.warning");
            }}
          ></div>
          <div
            className="tiles danger"
            onClick={() => {
              setBgColor("navbar-danger");
              setThemeClass(".tiles.danger");
            }}
          ></div>
          <div
            className="tiles primary"
            onClick={() => {
              setBgColor("navbar-primary");
              setThemeClass(".tiles.primary");
            }}
          ></div>
          <div
            className="tiles info"
            onClick={() => {
              setBgColor("navbar-info");
              setThemeClass(".tiles.info");
            }}
          ></div>
          <div
            className="tiles default border"
            onClick={() => {
              setBgColor("");
              setThemeClass(".tiles.default.border");
            }}
          ></div>
          <div
            className="tiles dark"
            onClick={() => {
              setBgColor("navbar-dark");
              setThemeClass(".tiles.dark");
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
