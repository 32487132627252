import React from "react";
import "./../../App.css";

import Sidebar from "./Layout/Sidebar";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import ThemeSetting from "./Layout/ThemeSetting";

function DashLayout({ children }) {
  return (
    <>
      <div className="container-scroller">
        {/* ================================== */}
        {/* Header */}
        <Header />
        <div className="container-fluid page-body-wrapper">
          {/* Theme setting  */}
          <ThemeSetting />
          {/* ====================================== */}
          {/* Sidebar */}
          <Sidebar />
          {/* ===================================== */}
          <div className="main-panel">
            {/* Child Component to render */}
            {children}
            {/* ================================== */}
            {/* Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashLayout;
