import React, { useEffect, useState } from "react";
import logoMini from "./../../../assets/images/diamonds_icon.png";
import { Link, NavLink, useLocation } from "react-router-dom";

function Sidebar() {
  const [isStatus, setIsStatus] = useState(false);
  const location = useLocation();
  function checkStatus() {
    if (navigator.onLine) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }

  useEffect(() => {
    checkStatus();
  }, []);

  setInterval(() => {
    checkStatus();
  }, 1000);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item">
          <div className="d-flex sidebar-profile">
            <div className="sidebar-profile-image">
              <img src={logoMini} alt="image" />
              <span
                className="sidebar-status-indicator"
                style={{ background: `${isStatus ? "#17c964" : "#FF0000"}` }}
              ></span>
            </div>
            <div className="sidebar-profile-name">
              <p className="sidebar-name">Admin Dashboard</p>
              <p className="sidebar-designation">Welcome</p>
            </div>
          </div>
          <p className="sidebar-menu-title">Dash menu</p>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/dashboard" ? "active" : ""
          }`}
        >
          <Link to="/dashboard" className="nav-link">
            <i className="typcn typcn-device-desktop menu-icon"></i>
            <span className="menu-title">
              Dashboard <span className="badge badge-primary ml-3">New</span>
            </span>
          </Link>
        </li>

        <li
          className={`nav-item ${
            location.pathname === "/successstate" ||
            location.pathname === "/errorstate" ||
            location.pathname === "/inprogressstate" ||
            location.pathname === "/sampletable" ||
            location.pathname === "/archivestate" ||
            location.pathname === "/unprocessedstate" ||
            location.pathname === "/pendingstate"
              ? "active"
              : ""
          }`}
        >
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#process"
            aria-expanded="false"
            aria-controls="process"
          >
            <i className="fa fa-tasks menu-icon"></i>
            <span className="menu-title">Process State</span>
            <i className="typcn typcn-chevron-right menu-arrow"></i>
          </a>
          <div className="collapse" id="process">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <NavLink to="/successstate" className="nav-link">
                  Success
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/inprogressstate" className="nav-link">
                  In Progress
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/pendingstate" className="nav-link">
                  Pending
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/errorstate" className="nav-link">
                  Errors
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/archivestate" className="nav-link">
                  Archived
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/unprocessedstate" className="nav-link">
                  Unprocessed
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/sampletable" className="nav-link">
                  Sample Table
                </NavLink>
              </li> */}
            </ul>
          </div>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/upload-links" ? "active" : ""
          }`}
        >
          <Link to="/upload-links" className="nav-link">
            <i className="fa fa-link menu-icon"></i>
            <span className="menu-title">Upload Links</span>
          </Link>
        </li>

        <li
          className={`nav-item ${
            location.pathname === "/public-url-share" ? "active" : ""
          }`}
        >
          <Link to="/public-url-share" className="nav-link">
            <i className="fa fa-reply-all menu-icon"></i>
            <span className="menu-title">Public Url Data</span>
          </Link>
        </li>
        {/* <li
          className={`nav-item ${
            location.pathname === "/client-data" ? "active" : ""
          }`}
        >
          <Link to="/client-data" className="nav-link">
            <i className="fa fa-reply menu-icon"></i>
            <span className="menu-title">Private Url Data</span>
          </Link>
        </li> */}
        <li
          className={`nav-item ${
            location.pathname === "/rebrand" ||
            location.pathname === "/client-data"
              ? "active"
              : ""
          }`}
        >
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#rebrand"
            aria-expanded="false"
            aria-controls="rebrand"
          >
            <i className="fa fa-reply menu-icon"></i>
            <span className="menu-title">Private Url Data</span>
            <i className="typcn typcn-chevron-right menu-arrow"></i>
          </a>
          <div className="collapse" id="rebrand">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <NavLink to="/private-url-client-data" className="nav-link">
                  Client Data Table
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/client-data" className="nav-link">
                  Private Url Data Table
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/* <li
          className={`nav-item ${
            location.pathname === "/360view" ? "active" : ""
          }`}
        >
          <Link to="/360view" className="nav-link">
            <i className="typcn typcn-media-play menu-icon"></i>
            <span className="menu-title">360 view</span>
          </Link>
        </li> */}
        {/* <li
          className={`nav-item ${
            location.pathname === "/videoplayer" ? "active" : ""
          }`}
        >
          <Link to="/videoplayer" className="nav-link">
            <i className="typcn typcn-media-play menu-icon"></i>
            <span className="menu-title">Video Player</span>
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#ui-basic"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <i className="typcn typcn-briefcase menu-icon"></i>
            <span className="menu-title">Side Menu</span>
            <i className="typcn typcn-chevron-right menu-arrow"></i>
          </a>
          <div className="collapse" id="ui-basic">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Chil Menu 1
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Chil Menu 2
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Chil Menu 3
                </Link>
              </li>
            </ul>
          </div>
        </li> */}

        {/* <li className="nav-item">
          <Link to="/dashboard" className="nav-link">
            <i className="typcn typcn-document-text menu-icon"></i>
            <span className="menu-title">Side Menu</span>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}

export default Sidebar;
