import React, { useState, useEffect, useRef } from "react";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs"; // You can choose different styles
function PrivateUrlLogs() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();

  // // For getting data
  // const [data, setData] = useState([]);
  // const getData = async () => {
  //   const id = localStorage.getItem("adminId")?.replace(/"/g, "");
  //   let BASE_URL =
  //     SERVER_URL +
  //     `/api/admin/get-private-url-logs/${id}`;
  //   const res = await fetch(BASE_URL, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${JSON.parse(
  //         localStorage.getItem("logged_res")
  //       )}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const result = await res.json();
  //   setData(result.data);
  // };
  const [logs, setLogs] = useState("");

  const streamLogs = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-private-url-logs/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setLogs(result.data);
  };

  useEffect(() => {
    streamLogs();
  }, []);
  const handleButtonClick = () => {
    streamLogs();
  };

  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Private Url Logs {" "}
                <span>
                  <button
                    className="btn btn-sm bg-white btn-icon-text border"
                    onClick={handleButtonClick}
                  >
                    Refresh
                    <i
                      className="typcn typcn-refresh"
                      style={{ height: "4px", width: "4px" }}
                    ></i>{" "}
                  </button>
                </span>
              </h4>
              <SyntaxHighlighter language="plaintext" style={docco}>
                {logs}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivateUrlLogs;
