import React, { useState, useEffect, useRef } from "react";
import adminImg from "../../../assets/images/adminImg.png";
import AdminUpdate from "./AdminUpdate";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";

function AddMember() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();
  const admin_id = localStorage.getItem("adminId")?.replace(/"/g, "");
  const [showPassowrd, setShowPassowrd] = useState(false);
  const [for_email, setForEmail] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm
  );
  const addMember = async (event) => {
    event.preventDefault();
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading addMemberBtn"></span>`;

    var BASE_URL = SERVER_URL + `/api/admin/register-member`;
    const name = event.target.name.value;
    const email = event.target.email.value;
    const role = event.target.role.value;
    const phone = event.target.phone.value;
    const password = event.target.password.value;
    const confirm_password = event.target.confirmPassword.value;

    if (email && !email.match(for_email)) {
      alert.show(`Enter Valid Email`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      document.getElementById("loginBtn").innerHTML = `Add Member`;
      return false;
    }
    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        admin_id: admin_id, // To validate token
        name: name,
        email: email,
        role: role,
        phone: phone,
        password: password,
        confirm_password: confirm_password,
      }),
    });
    const data = await res.json();
    if (res.ok) {
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.SUCCESS,
        });
      }, 1000);

      // window.alert(data.message + "Message Coming");
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Add Member`;
      }, 500);
    } else {
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.ERROR,
        });
      }, 1000);

      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Add Member`;
      }, 500);
      // document.getElementById("reset").reset();
    }
  };
  function hideShowPassword() {
    let password = document.getElementById("password");
    let confirm_password = document.getElementById("confirm_password");
    if (showPassowrd) {
      password.type = "password";
      confirm_password.type = "password";
      setShowPassowrd(false);
    } else {
      password.type = "text";
      confirm_password.type = "text";
      setShowPassowrd(true);
    }
  }
  const buttonClick = () => {
    alert.show(`Just Checking Working`, {
      position: positions.TOP_CENTER,
      type: types.SUCCESS,
      onOpen: () => {
        console.log("hey");
      }, // callback that will be executed after this alert open
      onClose: () => {
        console.log("closed");
      }, // callback that will be executed after this alert is removed
    });
    console.log("Clicked");
  };

  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Member</h4>

              {/* <div>
                <button className="btn btn-dark" onClick={buttonClick}>
                  Click check
                </button>
              </div> */}

              <form
                className="forms-sample"
                autoComplete="off"
                onSubmit={addMember}
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name">Username</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Username"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="email">Email address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="exampleSelectGender">Member's Type</label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="role"
                      >
                        {/* 0 = Super Admin, 1 = sub-admin, 2 = memeber */}
                        <option disabled>Select Type</option>
                        <option value="2">Sub-Admin</option>
                        <option value="3">Member</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Phone"
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="confirm_password">Confirm Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        id="confirm_password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group mb-3">
                      <div className="form-check form-check-flat form-check-primary">
                        <label htmlFor="checkBox" className="form-check-label">
                          Show Password{" "}
                          <input
                            type="checkbox"
                            id="checkBox"
                            className="form-check-input"
                            onClick={() => hideShowPassword()}
                          />
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2 px-5 py-3"
                    id="loginBtn"
                  >
                    Add Member
                  </button>
                </div>
                {/* <div className="form-group">
                    <button
                      id="loginBtn"
                      type="submit"
                      className="form-control btn btn-primary rounded signInBtn px-3"
                    >
                      Sign In
                    </button>
                  </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMember;
