import React from "react";

const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <div className="alertMessages" style={style}>
      {options.type === "info" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#2E9AFE"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px", minMidth: "24px" }}
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="16" x2="12" y2="12"></line>
          <line x1="12" y1="8" x2="12" y2="8"></line>
        </svg>
      )}
      {options.type === "success" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#31B404"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px", minMidth: "24px" }}
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
      )}
      {options.type === "error" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#FF0040"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px", minMidth: "24px" }}
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12" y2="16"></line>
        </svg>
      )}

      {message}
      <span
        className="closeMessage"
        style={{ marginLeft: "8px", minMidth: "24px" }}
        onClick={close}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </span>
    </div>
  );
};

export default AlertTemplate;
