import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
// import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import { encode, decode } from "base-64";
const ClientDataTable = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const navigate = useNavigate();
  const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
  // const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row

  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [diamondId, setDiamondId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [clientId, setClientId] = useState("");
  const totalPages = Math.ceil(totalRecords / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math?.min(startItem + data?.length - 1, totalRecords);

  // ========================================[For Api and Data set End]===================================================
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    // let BASE_URL = SERVER_URL + `/api/admin/get-private-url-data/${id}`;
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-private-url-data/${id}?page=${currentPage}&pageSize=${pageSize}&diamond_id=${diamondId}&supplier_id=${supplierId}&client_id=${clientId}`;
    // // Check if cached data exists
    // const cachedData = localStorage.getItem("cachedClientData");
    // if (cachedData) {
    // setData(JSON.parse(cachedData));
    // //  return;
    // }
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    if (res.ok) {
      setData(result.data);
      setTotalRecords(result.totalCount);
      gridRef.current.api.hideOverlay();
    } else {
    }
    gridRef.current.api.hideOverlay();

    // // Cache the data
    // localStorage.setItem("cachedClientData", JSON.stringify(result.data));
  };
  let check = true;
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(false);
  useEffect(() => {
    if (check) {
      // Show the loading overlay here when either diamondId or supplierId changes
      if (loadingOverlayVisible) {
        gridRef.current.api.showLoadingOverlay();
      }
  
      getData();
      setLoadingOverlayVisible(true); // Set the flag to true after the first API call
    }
    check = false;
  }, [currentPage, pageSize, diamondId, supplierId, clientId]);

  // Add a function to handle page change
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    gridRef.current.api.showLoadingOverlay();
  };

  // Add a function to handle page size change
  const onPageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    gridRef.current.api.showLoadingOverlay();
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  const onGridReady = useCallback((params) => {
    params.api.showLoadingOverlay();
  });
  //   // ========================================[For Api and Data set End]===================================================
  //   // ========================================[For Filter In Progress Start]===================================================
  //   const [inProgressFilter, setInProgressFilter] = useState([]);
  //   function filterFun() {
  //     data.map(() => {
  //       const datas = data.filter(
  //         (d) =>
  //           (d.status === "Pending" || d.status === "") && d.process_flag === 0
  //         // console.log(blog.category === activeCategory)
  //       );
  //       const updatedData = datas.map((row, index) => {
  //         return {
  //           ...row,
  //           index: (currentPage - 1) * Object.keys(datas).length + index + 1,
  //         };
  //       });
  //       setInProgressFilter(updatedData);
  //     });
  //   }
  //   useEffect(() => {
  //     filterFun();
  //   }, [data]);
  //   // ========================================[For Filter In Progress End]===================================================

  //   const numberToColor = (val) => {
  //     if (val === "In Progress") {
  //       return "badge-info";
  //     } else if (val === "Success") {
  //       return "badge-success";
  //     } else if (val === "Pending") {
  //       return "badge-warning";
  //     } else if (val === "Error") {
  //       return "badge-danger";
  //     } else {
  //       return "badge-primary";
  //     }
  //   };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);
  const alert = useAlert();

  //  =======================================[For getting Id for delete]======================================
  const buttonClicked = (cellValue) => {
    const confirmation = window.confirm(
      "Are you sure? You want to delete this data!"
    );
    if (confirmation) {
      console.log(`${cellValue} Delete This Id`);
    }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };

  // =========================[For Getting Data based on diamond id]==============================
  const [privateData, setPrivateData] = useState([]);
  const getPrivateDataById = async (client_id, diamond_id) => {
    setStoreId(client_id);
    setStoreDiamondId(diamond_id);
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL =
      SERVER_URL +
      `/api/admin/get-private-data-cdId/${id}/${diamond_id}/${client_id}`;
    let result = await fetch(BASE_URL, {
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
    });
    result = await result.json();
    setPrivateData(result.data);
  };
  // =========================[For Resend Url on diamond id]==============================

  //  =======================================[For getting Id for delete]======================================
  const copyLinkBtn = async (cellValue, client_id, supplier_id) => {
    // const confirmation = window.confirm("Are you sure? You want to view link");
    // const confirmation = window.confirm("Are you sure? You want to copy link");
    // if (confirmation) {
    // console.log(`${cellValue} Copy link for this diamond_id`);
    // if (!access_key || access_key === null) {
    //   window.alert("First Generate URL for this Diamond Id !");
    // } else {
    await navigator.clipboard.writeText("");
    await navigator.clipboard.writeText(
      CLIENT_URL +
        "/videoplayerclient360/" +
        encode(cellValue) +
        // "/" +
        // access_key +
        "/" +
        encode(supplier_id) +
        "/" +
        encode(client_id)
    );
    // window.alert("Link copied");
    alert.show(`Link copied`, {
      position: positions.TOP_CENTER,
      type: types.SUCCESS,
    });
    setTimeout(() => {
      alert.show(`Link copied`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
    }, 500);
    // }
    // }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };

  const viewLinkBtn = async (cellValue, client_id, supplier_id) => {
    // const confirmation = window.confirm("Are you sure? You want to view link");
    const confirmation = window.confirm("Are you sure? You want to view link");
    if (confirmation) {
      // console.log(`${cellValue} View link for this diamond_id`);
      // console.log(`${token} Token for diamond_id`);
      window.open(
        CLIENT_URL +
          "/videoplayerclient360/" +
          encode(cellValue) +
          // "/" +
          // access_key +
          "/" +
          encode(supplier_id) +
          "/" +
          encode(client_id),
        "_blank"
      );
      // navigate(
      //   "/videoplayerclient360/" +
      //     cellValue +
      //     // "/" +
      //     // access_key +
      //     // "/" +
      //     // web_url +
      //     "/" +
      //     client_id
      // );
    }
  };

  const [dateVal, setDateVal] = useState({
    date: "",
    webUrl: "",
    status: "",
  });

  const [storeId, setStoreId] = useState("");
  const [storeDiamondId, setStoreDiamondId] = useState("");

  let name, value;
  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setDateVal({ ...dateVal, [name]: value });
    console.log({ ...dateVal, [name]: value });
  };

  // =========================[For Edit Expiry on client id]==============================
  const editExpiryTime = async (event) => {
    event.preventDefault();
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL = SERVER_URL + `/api/admin/edit-expiry-time/${id}`;

    const storeDate =
      !dateVal.date || dateVal.date === null
        ? privateData.expireAt
        : dateVal.date;

    const res = await fetch(BASE_URL, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // url: urlValue.urlInput,
        expireAt: storeDate,
        client_id: storeId,
        diamond_id: storeDiamondId,
      }),
    });

    const data = await res.json();
    if (res.ok) {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.SUCCESS,
        });
      }, 500);
      getData();
    } else {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.ERROR,
        });
      }, 500);
      getData();
    }
  };
  // =========================[For Edit Web URL on client id]==============================
  const editWebUrl = async (event) => {
    gridRef.current.api.showLoadingOverlay();
    event.preventDefault();
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL = SERVER_URL + `/api/admin/edit-private-web-url/${id}`;

    const storeUrl =
      !dateVal.webUrl || dateVal.webUrl === null
        ? privateData.web_url
        : dateVal.webUrl;

    const res = await fetch(BASE_URL, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // url: urlValue.urlInput,
        url: storeUrl,
        client_id: storeId,
      }),
    });

    const data = await res.json();
    if (res.ok) {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.SUCCESS,
        });
      }, 500);
      getData();
    } else {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.ERROR,
        });
      }, 500);
      getData();
    }
  };
  // =========================[For Edit Status on client id]==============================
  const editStatus = async (event) => {
    gridRef.current.api.showLoadingOverlay();
    event.preventDefault();
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL = SERVER_URL + `/api/admin/edit-private-url-status/${id}`;
    const storeStatus =
      !dateVal.status || dateVal.status === null
        ? privateData.status
        : dateVal.status;
    const res = await fetch(BASE_URL, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // url: urlValue.urlInput,
        status: storeStatus,
        client_id: storeId,
      }),
    });

    const data = await res.json();
    if (res.ok) {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.SUCCESS,
        });
      }, 500);
      getData();
    } else {
      // window.alert(`${data.message}`);
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.ERROR,
        });
      }, 500);
      getData();
    }
  };
  // }
  // alert.show(`${cellValue} Delete This Id`, {
  //   position: positions.TOP_CENTER,
  //   type: types.ERROR,
  // });

  // Col Defs For Sample Pending Data Start===============================================
  // =========================================[For Diamond trn_log Data]============================================

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "S.No",
      // field: "index",
      valueGetter: (params) => params.node.rowIndex + 1,
      width: 150,
      // For selection
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { headerName: "Client Id", field: "client_id", width: 150 },
    { headerName: "Diamond Id", field: "diamond_id" },
    { headerName: "Supplier Id", field: "supplier_id", width: 150 },
    { headerName: "Web Url", field: "web_url", minWidth: 280 },
    // { headerName: "Access Key", field: "access_key" },
    { headerName: "Valid Till", field: "expireAt" },
    {
      headerName: "Status",
      field: "status",
      width: 160,
      cellRenderer: (params) => (
        <label
          style={{ width: "100px" }}
          className={`badge ${
            params.value === "Expired" ? "badge-danger" : "badge-success"
          }`}
        >
          {params.value}
        </label>
      ),
    },

    {
      headerName: "Actions",
      field: "diamond_id",
      minWidth: 550,

      cellRenderer: (params) => (
        <>
          {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++Start From Here++++++++++++++++++++++++++++++++++++++++++++++++ */}
          {/* <button
            onClick={() => getPrivateDataById(params.data.client_id, params.value)}
            data-toggle="modal"
            data-target="#exampleModalCenter"
            className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
          >
            Revalidate
            <i className="typcn typcn-edit btn-icon-append"></i>
          </button>{" "} */}
          <button
            onClick={() =>
              getPrivateDataById(params.data.client_id, params.value)
            }
            data-toggle="modal"
            data-target="#exampleModalCenterThird"
            className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
          >
            Status Change
            <i className="typcn typcn-edit btn-icon-append"></i>
          </button>{" "}
          {/* <button
            onClick={() => getPrivateDataById(params.data.client_id, params.value)}
            data-toggle="modal"
            data-target="#exampleModalCenterSecond"
            className="btn btn-sm btn-outline-dark btn-icon-text mr-1"
          >
            Update URL
            <i className="typcn typcn-edit btn-icon-append"></i>
          </button>{" "} */}
          <button
            onClick={() =>
              viewLinkBtn(
                params.value,
                // params.data.access_key,
                // params.data.web_url,
                params.data.client_id,
                params.data.supplier_id
              )
            }
            className="btn btn-sm btn-outline-primary btn-icon-text"
          >
            View
            <i className="fa fa-eye btn-icon-append" aria-hidden="true"></i>
          </button>{" "}
          &nbsp;
          <button
            onClick={() =>
              copyLinkBtn(
                params.value,
                // params.data.access_key,
                // params.data.web_url,
                params.data.client_id,
                params.data.supplier_id
              )
            }
            className="btn btn-sm btn-outline-info btn-icon-text"
          >
            Copy Link
            <i className="fa fa-link btn-icon-append" aria-hidden="true"></i>
          </button>
        </>
      ),
    },
  ]);

  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return sel.data.id;
  });
  // console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    // console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      // debounceMs: 100,
    },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  // Example load data from sever
  // useEffect(() => {
  //   fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //     .then((result) => result.json())

  //     .then((rowData) => setRowData(rowData));
  // }, []);

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
  const handleButtonClick = () => {
    gridRef.current.api.showLoadingOverlay();
    getData();
  };

  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h4 className="table_title card-title mb-1">
                  Manage Private URL Share Data Table{" "}
                  <span>
                    <button
                      className="btn btn-sm bg-white btn-icon-text border"
                      onClick={handleButtonClick}
                    >
                      Refresh
                      <i
                        className="typcn typcn-refresh"
                        style={{ height: "4px", width: "4px" }}
                      ></i>{" "}
                    </button>
                  </span>
                </h4>
                <div className="manageTablesOptions">
                  {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}
                  <div>
                    <button
                      type="button"
                      className="btn excelBtn btn-sm btn-dark btn-icon-text"
                      onClick={() => navigate("/private-url-logs")}
                    >
                      Private Url Logs
                      <i className="typcn typcn-document btn-icon-append"></i>
                    </button>
                  </div>
                  <select
                    className="dropdown setpageSelection show form-control"
                    name="pagination"
                    style={{
                      padding: ".5rem .75rem",
                      borderRadius: "4px",
                    }}
                    value={pageSize}
                    onChange={onPageSizeChange}
                  >
                    <option value={25}>show 25 rows</option>
                    <option value={50}>show 50 rows</option>
                    <option value={100}>show 100 rows</option>
                    <option value={250}>show 250 rows</option>
                  </select>
                  {/* <select
              className="dropdown setpageSelection show form-control"
              name="pagination"
              style={{
                padding: ".5rem .75rem",
                borderRadius: "4px",
              }}
              onChange={(event) => setPagination(event)}
            >
              <option value={10}>show 10 rows</option>
              <option value={20}>show 20 rows</option>
              <option value={25}>show 25 rows</option>
              <option value={50}>show 50 rows</option>
            </select> */}

                  <div>
                    <button
                      type="button"
                      className="btn excelBtn btn-sm btn-info btn-icon-text"
                      onClick={onBtnExport}
                    >
                      Excel
                      <i className="typcn typcn-download btn-icon-append"></i>
                    </button>
                  </div>
                </div>
              </div>
              <h3 className="table_title card-title mb-1">Filter</h3>
              <div>
                <form className="forms-sample" autoComplete="off">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="diamondId">Diamond ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="diamondId"
                          value={diamondId}
                          onChange={(e) => setDiamondId(e.target.value)}
                          placeholder="Diamond ID"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="supplierId">Supplier ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="supplierId"
                          value={supplierId}
                          onChange={(e) => setSupplierId(e.target.value)}
                          placeholder="Supplier ID"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="clientId">Client ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="clientId"
                          value={clientId}
                          onChange={(e) => setClientId(e.target.value)}
                          placeholder="Client ID"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 680 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={data} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  // paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  enableCellTextSelection={true} // For cell data selection
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  rowHeight={50}
                  onGridReady={onGridReady}
                  overlayLoadingTemplate={
                    '<span className="ag-overlay-loading-center">Please wait while your records are loading...</span>'
                  }
                  // pagination={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <div
                    id="order-listing_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="order-listing_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing {startItem} to {endItem} of {totalRecords}{" "}
                          entries{" "}
                          {/* Replace "10" with the number of items per page */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="order-listing_paginate"
                        >
                          <ul className="pagination">
                            {/* Render the "Previous" button */}
                            <li
                              className={`paginate_button page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              id="order-listing_previous"
                              // onClick={() => handlePageChange(currentPage - 1)}
                              onClick={() => {
                                if (currentPage !== 1) {
                                  onPageChange(1);
                                }
                              }}
                              disabled={currentPage === 1}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="0"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-angle-double-left"
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                  }}
                                ></i>
                              </a>
                            </li>
                            <li
                              className={`paginate_button page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              id="order-listing_previous"
                              // onClick={() => handlePageChange(currentPage - 1)}
                              onClick={() => {
                                if (currentPage !== 1) {
                                  onPageChange(currentPage - 1);
                                }
                              }}
                              disabled={currentPage === 1}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="0"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-chevron-left"
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                  }}
                                ></i>
                              </a>
                            </li>

                            {/* Render the current page number */}
                            <li className="paginate_button page-item active">
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="1"
                                tabIndex="0"
                                className="page-link"
                              >
                                {currentPage}
                              </a>
                            </li>

                            {/* Render the "Next" button */}
                            <li
                              className={`paginate_button page-item ${
                                currentPage * pageSize >= totalRecords
                                  ? "disabled"
                                  : ""
                              }`}
                              id="order-listing_next"
                              // onClick={() => handlePageChange(currentPage + 1)}
                              onClick={() => {
                                if (currentPage * pageSize < totalRecords) {
                                  onPageChange(currentPage + 1);
                                }
                              }}
                              // disabled={currentPage * pageSize >= totalRecords}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="2"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-chevron-right"
                                  style={{ height: "4px", width: "4px" }}
                                ></i>
                              </a>
                            </li>
                            <li
                              className={`paginate_button page-item ${
                                currentPage * pageSize >= totalRecords
                                  ? "disabled"
                                  : ""
                              }`}
                              id="order-listing_next"
                              // onClick={() => handlePageChange(currentPage + 1)}
                              onClick={() => {
                                if (currentPage !== totalPages) {
                                  onPageChange(totalPages);
                                }
                              }}
                              // disabled={currentPage * pageSize >= totalRecords}
                            >
                              <a
                                href="#"
                                aria-controls="order-listing"
                                data-dt-idx="2"
                                tabIndex="0"
                                className="page-link"
                              >
                                <i
                                  className="fa fa-angle-double-right"
                                  style={{ height: "4px", width: "4px" }}
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Revalidate Date/Time
              </h5>
              <button
                type="button"
                className="close btnClose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="date">Date/Time</label>
                <input
                  type="date"
                  name="date"
                  className="form-control"
                  id="date"
                  // defaultValue={trnData.url}
                  onChange={handleInputChange}
                  defaultValue={
                    dateVal.date || (dateVal.date = privateData.expireAt)
                  }
                  autoComplete="off"
                  placeholder="Enter URL"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={getData}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-dark appButton"
                onClick={editExpiryTime}
              >
                Update Date/Time
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenterSecond"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Modify Web URL
              </h5>
              <button
                type="button"
                className="close btnClose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="webUrl">Web URL</label>
                <input
                  type="text"
                  name="webUrl"
                  className="form-control"
                  id="webUrl"
                  // defaultValue={trnData.url}
                  onChange={handleInputChange}
                  defaultValue={
                    dateVal.webUrl || (dateVal.webUrl = privateData.web_url)
                  }
                  autoComplete="off"
                  placeholder="Enter URL"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={getData}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-dark appButton"
                onClick={editWebUrl}
              >
                Update Web Url
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenterThird"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Change Status
              </h5>
              <button
                type="button"
                className="close btnClose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  name="status"
                  className="dropdown form-control"
                  onChange={handleInputChange}
                  value={
                    dateVal.status || (dateVal.status = privateData.status)
                  }
                >
                  {/* <option defaultValue={
                    dateVal.status || (dateVal.status = privateData.status)
                  } disabled>Status</option> */}
                  <option value="Active">Active</option>
                  <option value="Expired">Expired</option>
                </select>
                {console.log(dateVal.status)}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={getData}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-dark appButton"
                onClick={editStatus}
              >
                Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDataTable;
