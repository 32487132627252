import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import DataTable from "./ProcessDataTables/DataTable";
import { NavLink, Link } from "react-router-dom";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
function Dashboard() {
  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const alert = useAlert();
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // const getData = async () => {
  //   const id = localStorage.getItem("adminId")?.replace(/"/g, "");
  //   let BASE_URL = SERVER_URL + `/api/admin/get-trn-data/${id}`;
  //   // Check if cached data exists
  //   // const cachedData = localStorage.getItem("cachedData");
  //   // if (cachedData) {
  //     // setData(JSON.parse(cachedData));
  //     // // return;
  //   // }
  //   const res = await fetch(BASE_URL, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${JSON.parse(
  //         localStorage.getItem("logged_res")
  //       )}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const result = await res.json();
  //   const updatedData = result.data.map((row, index) => {
  //     return {
  //       ...row,
  //       index: (currentPage - 1) * Object.keys(result).length + index + 1,
  //     };
  //   });
  //   setData(updatedData);
  //   // Cache the data
  //   // localStorage.setItem("cachedData", JSON.stringify(updatedData));
  // };

  // useEffect(() => {
  //   getData();
  // // }, [setData]);
  // }, []);

  // ========================================[For Filter Pending Start]===================================================
  const [pendingFilter, setPendingFilter] = useState([]);
  async function pendingFilterFun() {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-trn-pending-count/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setPendingFilter(result.data.PendingCount);
    // Check if cached data exists
    // const cachedData = localStorage.getItem("cachedData");
    // if (cachedData) {
    // setData(JSON.parse(cachedData));
    // // return;
    // }

    // data.map(() => {
    //   const datas = data.filter(
    //     (d) =>
    //       (d.status === "Pending" || d.status === "") && d.process_flag === 1
    //     // console.log(blog.category === activeCategory)
    //   );
    //   setPendingFilter(datas);
    // });
  }
  useEffect(() => {
    pendingFilterFun();
  }, []);
  // ========================================[For Filter Success Start]===================================================
  const [successFilter, setSuccessFilter] = useState([]);
  async function successFilterFun() {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-trn-success-count/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setSuccessFilter(result.data.SuccessCount);
    // data.map(() => {
    //   const datas = data.filter(
    //     (d) => d.status === "Success"
    //     // console.log(blog.category === activeCategory)
    //   );

    //   setSuccessFilter(datas);
    // });
  }
  useEffect(() => {
    successFilterFun();
  }, []);
  // ========================================[For Filter Error Start]===================================================
  const [errorFilter, setErrorFilter] = useState([]);
  async function errorFilterFun() {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-trn-error-count/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setErrorFilter(result.data.ErrorCount);
    // data.map(() => {
    //   const datas = data.filter(
    //     (d) => d.status === "Error"
    //     // console.log(blog.category === activeCategory)
    //   );

    //   setErrorFilter(datas);
    // });
  }
  useEffect(() => {
    errorFilterFun();
  }, []);
  // ========================================[For Filter Error End]===================================================
  // ========================================[For Filter In Progress Start]===================================================
  const [inProgressFilter, setInProgressFilter] = useState([]);
  async function inProgressFilterFun() {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-trn-inprogress-count/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setInProgressFilter(result.data.InProgressCount);
    // data.map(() => {
    //   const datas = data.filter(
    //     (d) =>
    //       (d.status === "Pending" || d.status === "") && d.process_flag === 0
    //     // console.log(blog.category === activeCategory)
    //   );

    //   setInProgressFilter(datas);
    // });
  }
  useEffect(() => {
    inProgressFilterFun();
  }, []);
  // ========================================[For Filter In Progress End]===================================================
  // ========================================[For Filter In Progress Start]===================================================
  const [archiveFilter, setArchiveFilter] = useState([]);
  async function archiveFilterFun() {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-trn-archive-count/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setArchiveFilter(result.data.ArchiveCount);
    // data.map(() => {
    //   const datas = data.filter(
    //     (d) =>
    //       d.status === "Archived"
    //     // console.log(blog.category === activeCategory)
    //   );

    //   setInProgressFilter(datas);
    // });
  }
  useEffect(() => {
    archiveFilterFun();
  }, []);
  // ========================================[For Filter In Progress End]===================================================
  // ========================================[For Filter In Unprocessed Start]===================================================
  const [unprocessedFilter, setUnprocessedFilter] = useState([]);
  async function unprocessedFilterFun() {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-trn-unprocessed-count/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setUnprocessedFilter(result.data.UnprocessedCount);
  }
  useEffect(() => {
    unprocessedFilterFun();
  }, []);
  // ========================================[For Filter In Unprocessed End]===================================================
  const [refresh, setRefresh] = useState(false);

  const handleButtonClick = async () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 1000);
    // await getData();
    pendingFilterFun();
    successFilterFun();
    inProgressFilterFun();
    errorFilterFun();
    archiveFilterFun();
    unprocessedFilterFun();
  };

  // const buttonClick = () => {
  //   alert.show(`Just Checking Working`, {
  //     position: positions.TOP_CENTER,
  //     type: types.SUCCESS,
  //     onOpen: () => {
  //       console.log("hey");
  //     }, // callback that will be executed after this alert open
  //     onClose: () => {
  //       console.log("closed");
  //     }, // callback that will be executed after this alert is removed
  //   });
  //   setTimeout(() => {
  //     alert.show(`Just Checking Working`, {
  //       position: positions.TOP_CENTER,
  //       type: types.SUCCESS,
  //       onOpen: () => {
  //         console.log("hey");
  //       }, // callback that will be executed after this alert open
  //       onClose: () => {
  //         console.log("closed");
  //       }, // callback that will be executed after this alert is removed
  //     });
  //   }, 1000);
  //   console.log("Clicked");
  // };

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-sm-6">
          <h3 className="mb-1 font-weight-bold">Admin Dashboard</h3>

          {/* <div>
            <button className="btn btn-dark" onClick={buttonClick}>
              Click check
            </button>
          </div> */}
          {/* <p>Your last login: 21h ago from India.</p> */}
        </div>
        <div className="col-sm-6">
          <div className="d-flex align-items-center justify-content-md-end">
            <div className="mb-3 mb-xl-0 pr-1">
              <span>
                <button
                  className="btn btn-sm bg-white btn-icon-text"
                  onClick={handleButtonClick}
                >
                  Refresh
                  <i
                    className="typcn typcn-refresh"
                    style={{ height: "4px", width: "4px" }}
                  ></i>{" "}
                </button>
              </span>
              {/* <div className="dropdown">
                <button
                  className="btn bg-white btn-sm dropdown-toggle btn-icon-text border mr-2"
                  type="button"
                  id="dropdownMenu3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="typcn typcn-calendar-outline mr-2"></i>Last 7
                  days
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuSizeButton3"
                  data-x-placement="top-start"
                >
                  <h6 className="dropdown-header">Last 14 days</h6>
                  <a className="dropdown-item" href="#">
                    Last 21 days
                  </a>
                  <a className="dropdown-item" href="#">
                    Last 28 days
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row  mt-3">
        <div className="row" style={{marginLeft:"0px", marginRight:"0px"}}>
        {/* <div className="row" style={{justifyContent: "space-between", marginLeft:"0px", marginRight:"0px"}}> */}
          {/* <div className="col-xl-12 d-flex grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-wrap justify-content-between">
                <h4 className="card-title mb-3">Admin Dashboard</h4>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="text-secondary mb-1 font-weight-bold">
                        13,221
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

          <div className="col-xl-2 col-md-6 d-flex grid-margin stretch-card ">
            <div className="card countCards">
              <div className="card-body">
                <NavLink to="/successstate" style={{ textDecoration: "none" }}>
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title mb-3">Success</h4>
                  </div>
                  <h2 className="text-success mb-1 font-weight-bold">
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    {/* 11,542 */}
                    {/* {successFilter?.length} */}
                    {successFilter ? successFilter : 0}
                  </h2>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 d-flex grid-margin stretch-card ">
            <div className="card countCards">
              <div className="card-body">
                <NavLink
                  to="/inprogressstate"
                  style={{ textDecoration: "none" }}
                >
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title mb-3">In Progress</h4>
                  </div>
                  {/* <div className="mr-md-5 mb-4"> */}
                  <h2 className="text-info mb-1 font-weight-bold">
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>{" "}
                    {/* 10,342 */}
                    {/* {inProgressFilter?.length} */}
                    {inProgressFilter ? inProgressFilter : 0}
                  </h2>
                </NavLink>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 d-flex grid-margin stretch-card ">
            <div className="card countCards">
              <div className="card-body">
                <NavLink to="/pendingstate" style={{ textDecoration: "none" }}>
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title mb-3">Pending</h4>
                  </div>
                  <h2 className="text-warning mb-1 font-weight-bold">
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>{" "}
                    {/* 1,542 */}
                    {pendingFilter ? pendingFilter : 0}
                  </h2>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 d-flex grid-margin stretch-card ">
            <div className="card countCards">
              <div className="card-body">
                <NavLink to="/errorstate" style={{ textDecoration: "none" }}>
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title mb-3">Errors</h4>
                  </div>
                  {/* <div className="mr-md-5 mb-4"> */}
                  <h2 className="text-danger mb-1 font-weight-bold">
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>{" "}
                    {/* 23,342 */}
                    {/* {errorFilter?.length} */}
                    {errorFilter ? errorFilter : 0}
                  </h2>
                </NavLink>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 d-flex grid-margin stretch-card ">
            <div className="card countCards">
              <div className="card-body">
                <NavLink to="/archivestate" style={{ textDecoration: "none" }}>
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title mb-3">Archived</h4>
                  </div>
                  {/* <div className="mr-md-5 mb-4"> */}
                  <h2 className="text-primary mb-1 font-weight-bold">
                    <i className="fa fa-archive" aria-hidden="true"></i>{" "}
                    {/* 23,342 */}
                    {/* {errorFilter?.length} */}
                    {archiveFilter ? archiveFilter : 0}
                  </h2>
                </NavLink>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 d-flex grid-margin stretch-card ">
            <div className="card countCards">
              <div className="card-body">
                <NavLink to="/unprocessedstate" style={{ textDecoration: "none" }}>
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title mb-3">Unprocessed</h4>
                  </div>
                  {/* <div className="mr-md-5 mb-4"> */}
                  <h2 className="text-secondary mb-1 font-weight-bold">
                    <i className="fa fa-recycle" aria-hidden="true"></i>{" "}
                    {/* 23,342 */}
                    {/* {errorFilter?.length} */}
                    {unprocessedFilter ? unprocessedFilter : 0}
                  </h2>
                </NavLink>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <DataTable refreshVal={refresh} />

            {/* <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-1">Bordered table</h4>
                <button
                  type="button"
                  className="btn btn-sm btn-info btn-icon-text"
                >
                  Excel
                  <i className="typcn typcn-download btn-icon-append"></i>
                </button>
              </div>
              <div className="table-responsive pt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First name</th>
                      <th>Progress</th>
                      <th>Amount</th>
                      <th>Deadline</th>
                      <th>Action</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Herman Beck</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 77.99</td>
                      <td>May 15, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Messsy Adam</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$245.30</td>
                      <td>July 1, 2015</td>
                      <td>
                        <label className="badge badge-danger">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>John Richards</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            aria-valuenow="90"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$138.00</td>
                      <td>Apr 12, 2015</td>
                      <td>
                        <label className="badge badge-warning">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Peter Meggik</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 77.99</td>
                      <td>May 15, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Edward</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            aria-valuenow="35"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 160.25</td>
                      <td>May 03, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>John Doe</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 123.21</td>
                      <td>April 05, 2015</td>
                      <td>
                        <label className="badge badge-info">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Henry Tom</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 150.00</td>
                      <td>June 16, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
