import React, { useState, useEffect, useRef } from "react";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";

function RegisterPrivateUrlClient() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const alert = useAlert();
  const id = localStorage.getItem("adminId")?.replace(/"/g, "");

  const [clientData, setClientData] = useState({
    client_id: "",
    web_url: "",
  });
  const [image, setImage] = useState("");
  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setClientData({ ...clientData, [name]: value });
    console.log({ ...clientData, [name]: value });
  };

  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  const addClient = async (event) => {
    event.preventDefault();
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading addMemberBtn"></span>`;
    const { client_id, web_url } = clientData;
    var BASE_URL = SERVER_URL + `/api/admin/register-private-url-client/${id}`;
    const formData = new FormData();
    formData.append("client_id", client_id);
    formData.append("web_url", web_url);
    formData.append("watermark", image);
    console.log(formData);
    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
      body: formData,
    });
    const data = await res.json();
    if (res.ok) {
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.SUCCESS,
        });
      }, 1000);
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Add Client`;
      }, 2000);
    } else {
      alert.show(`${data.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      setTimeout(() => {
        alert.show(`${data.message}`, {
          position: positions.TOP_CENTER,
          type: types.ERROR,
        });
      }, 1000);
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Add Client`;
      }, 2000);
      // document.getElementById("reset").reset();
    }
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Client</h4>

              <form
                className="forms-sample"
                autoComplete="off"
                onSubmit={addClient}
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="client_id">Client Id</label>
                      <input
                        type="text"
                        name="client_id"
                        defaultValue={clientData.client_id}
                        onChange={handleFormData}
                        className="form-control"
                        id="client_id"
                        placeholder="Client Id"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="web_url">
                        Web Url *{" "}
                        <span style={{ fontSize: "11px", fontWeight: "600" }}>
                          (Note: Please include "https://" in Url)
                        </span>
                      </label>
                      <input
                        type="text"
                        name="web_url"
                        id="web_url"
                        defaultValue={clientData.web_url}
                        onChange={handleFormData}
                        className="form-control"
                        placeholder="Web Url (Ex - https://www.diamondsoncall.com/)"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="image">Watermark *</label>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        ref={fileInputRef}
                        onChange={handleImageData}
                        className="file-upload-default"
                      />
                      <div className="input-group col-xs-12">
                        <input
                          type="text"
                          className="form-control file-upload-info"
                          style={{ cursor: "pointer" }}
                          id="file-upload-info"
                          readOnly
                          placeholder={image ? image.name : "Upload Image"}
                          onChange={handleImageData}
                          // placeholder="Upload Image"
                          onClick={handleUploadButtonClick}
                        />
                        <span className="input-group-append">
                          <button
                            className="file-upload-browse btn btn-primary"
                            type="button"
                            onClick={handleUploadButtonClick}
                          >
                            Upload
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2 px-5 py-3"
                    id="loginBtn"
                  >
                    Add Client
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPrivateUrlClient;
