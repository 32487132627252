import React, { useState, useEffect } from "react";
// import "./App.css";
import next from "./../../../assets/playerIcons/next.png";
import play from "./../../../assets/playerIcons/play.png";
import pause from "./../../../assets/playerIcons/pause.png";
import previous from "./../../../assets/playerIcons/previous.png";
import reverse from "./../../../assets/playerIcons/reverse.png";
import forward from "./../../../assets/playerIcons/forward.png";
import zoomin from "./../../../assets/playerIcons/zoomin.png";
import zoomout from "./../../../assets/playerIcons/zoomout.png";
import reset from "./../../../assets/playerIcons/reset.png";
import backView from "./../../../assets/playerIcons/back-view.png";
import frontView from "./../../../assets/playerIcons/front-view.png";
import leftView from "./../../../assets/playerIcons/left-view.png";
import rightView from "./../../../assets/playerIcons/right-view.png";

import { encode } from "base-64";
import ThreeSixty from "./ThreeSixtyView.jsx";
// import ThreeSixty from "react-360-view";
import Hammer from "react-hammerjs";

const VideoPlayerTrial = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [frames, setFrames] = useState([]);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReversing, setIsReversing] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1.1);
  const [loader, setLoader] = useState(true);
  const [frameCount, setFrameCount] = useState(0);
  // Fetch image frames (you'll need to modify this based on your setup)
  let check = true;
  let folder = 0;
  let framesShort;
  let firstFrame;
  let framesAll;
  useEffect(() => {
    let imgURLLength = 0;
    // Fetch frames and store them in the state
    if (check) {
      const fetchFrames = async () => {
        firstFrame = true;
        check = false;
        const response = await fetch(SERVER_URL + `/getFrame/${folder}`);
        const framesData = await response.json();
        // To push single frame into array
        const framesToArray = [];
        framesToArray.push(framesData.images);
        setFrames(framesToArray);
        imgURLLength = framesToArray.length;
        setFrameCount(framesToArray.length + 1);
        preloadImages(framesToArray);
        // fetchFramesShort();
        fetchFramesAll();
      };

      // const fetchFramesShort = async () => {
      //   firstFrame = false;
      //   framesShort = true;
      //   folder = 1;
      //   const response = await fetch(SERVER_URL + `/getFrame/${folder}`);
      //   const framesData = await response.json();
      //   setFrames(framesData["images"]);

      //   imgURLLength = framesData["images"].length;
      //   setFrameCount(framesData["images"].length);
      //   preloadImages(framesData["images"]);
      //   fetchFramesAll();
      // };

      const fetchFramesAll = async () => {
        // check = false;
        framesShort = false;
        framesAll = true;
        folder = 2;
        const response = await fetch(SERVER_URL + `/getFrame/${folder}`);
        const framesData = await response.json();

        // const sortedImageUrls = await framesData["images"].sort((a, b) => {
        //   const indexA = parseInt(a.split("/").pop().split(".")[0]);
        //   const indexB = parseInt(b.split("/").pop().split(".")[0]);
        //   return indexA - indexB;
        // });
        setFrames(framesData["images"]);
        // console.log(sortedImageUrls);
        imgURLLength = framesData["images"].length;
        setFrameCount(framesData["images"].length);
        preloadImages(framesData["images"]);
      };

      fetchFrames();

      function preloadImages(imageData) {
        if (imageData.length) {
          try {
            // // frames.length = imageData.length;
            // // =============================================[Count of images]=========================================
            // state.imgLength = frames.length;
            // // localStorage.setItem('imgLength',state.imgLength)
            // // console.log(frames.length)
            imageData.forEach((src) => {
              //console.log(src)
              addImage(src);
            });
          } catch (error) {
            console.error(
              `Something went wrong while loading images: ${error}`
            );
          }
        } else {
          console.log("No Images Found");
        }
      }

      function addImage(resultSrc) {
        const image = new Image();
        image.onload = handleImageLoad.bind(this);
        image.src = resultSrc;
      }

      let loadedCount = 0;
      function handleImageLoad(event) {
        loadedCount += 1;
        //console.log(loadedCount, imgURLLength);
        // Check if all images have loaded
        if (loadedCount === imgURLLength) {
          setLoader(false);
          console.log("All images have loaded successfully!");
          // Perform any action you want when all images are loaded
        }
      }
    }
  }, []);

  // Handle play/reverse actions
  useEffect(() => {
    let frameInterval;

    const playFrames = () => {
      frameInterval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (isReversing) {
            if (prevFrame === 0) {
              setIsReversing(false);
              return prevFrame + 1;
            }
            return prevFrame - 1;
          }

          if (prevFrame === frames.length - 1) {
            setIsReversing(true);
            return prevFrame - 1;
          }

          return prevFrame + 1;
        });
      }, 50); // Adjust the interval to control the playback speed
    };

    if (isPlaying) {
      playFrames();
    }

    return () => {
      clearInterval(frameInterval);
    };
  }, [isPlaying, isReversing, frames.length]);

  // Handle frame navigation manually (e.g., by clicking forward/backward buttons)
  const navigateFrame = (step) => {
    setCurrentFrame((prevFrame) => {
      const newFrame = prevFrame + step;

      if (newFrame < 0) {
        return 0;
      }

      if (newFrame > frames.length - 1) {
        return frames.length - 1;
      }

      return newFrame;
    });
  };

  function resetPlayer() {
    setCurrentFrame(1);
    setIsPlaying(false);
  }

  // To disable context menu on right click=======================================
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById("my-component");
    rootElement.addEventListener("contextmenu", handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <>
      <div className="content-wrapper" id="my-component">
        <div className="row mt-3">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mt-3">
                  {/* <div className="video-player" style={{ marginTop: "50px" }}>
                    <div
                      className="ImageBox"
                      style={{
                        overflow: "hidden",
                        textAlign: "center",
                        width: "400px",
                        height: "400px",
                        margin: "auto",
                        position: "relative",
                        backgroundColor: "#CBCDCF",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {loader ? (
                        <div className="loading"></div>
                      ) : (
                        <>
                          <img
                            src={
                              "data:image/jpeg;base64," + frames[currentFrame]
                            }
                            alt="Video Frame"
                            style={{
                              transform: `scale(${zoomLevel})`,
                              marginBottom: "auto",
                              marginTop: "auto",
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div
                      className="controls"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <button onClick={() => resetPlayer()}>
                        <img src={reset} title="Reset" alt="" />
                      </button>
                      <button onClick={() => setIsPlaying(!isPlaying)}>
                        <img
                          src={isPlaying ? pause : play}
                          title={isPlaying ? "Pause" : "Play"}
                          alt=""
                        />
                      </button>
                      <button onClick={() => setIsReversing(!isReversing)}>
                        <img
                          src={isReversing ? reverse : reverse}
                          title="Reverse"
                          alt=""
                        />
                      </button>
                      <button onClick={() => setIsReversing(!isReversing)}>
                        <img
                          src={isReversing ? forward : forward}
                          title="Forward"
                          alt=""
                        />
                      </button>
                      <button onClick={() => navigateFrame(-1)}>
                        <img src={next} title="Next" alt="" />
                      </button>
                      <button onClick={() => navigateFrame(1)}>
                        <img src={previous} title="Previous" alt="" />
                      </button>
                      <button
                        onClick={() =>
                          setZoomLevel((prevZoom) => prevZoom * 1.1)
                        }
                      >
                        <img src={zoomin} title="Zoom In" alt="" />
                      </button>
                      <button
                        onClick={() =>
                          setZoomLevel((prevZoom) => prevZoom / 1.1)
                        }
                      >
                        <img src={zoomout} title="Zoom Out" alt="" />
                      </button>
                    </div>
                  </div> */}
                </div>
                <div className="row mt-3">
                  {loader && (
                    <>
                      <div
                        // className="justify-content-center"
                        style={{
                          overflow: "hidden",
                          textAlign: "center",
                          width: "600px",
                          height: "600px",
                          margin: "auto",
                          position: "relative",
                          backgroundColor: "#CBCDCF",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div className="loadingPlayer"></div>
                        <div
                          id="v360-menu-btns"
                          className="light"
                          // Added style show buttons below canvas
                          style={{
                            position: "absolute",
                          }}
                        >
                          <div
                            // className="controls"
                            className="v360-navigate-btns"
                            style={{
                              boxShadow:
                                "rgba(0, 0, 0, 0.23) 0px 0px 0px 0.1px",
                              padding: "2px",
                              borderRadius: "5px",
                            }}
                            // style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={play} title="Play" alt="" />
                            </button>
                            {/* <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={forward} title="Forward" alt="" />
                            </button> */}
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={next} title="Next" alt="" />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={previous} title="Previous" alt="" />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={reverse} title="Reverse" alt="" />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={reset} title="Reset" alt="" />
                            </button>
                            {/* <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={backView} title="Back View" alt="" />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={rightView} title="Right View" alt="" />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={frontView} title="Front View" alt="" />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={leftView} title="Left View" alt="" />
                            </button> */}
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={zoomin} title="Zoom In" alt="" />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img src={zoomout} title="Zoom Out" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    // className="justify-content-center"
                    style={{
                      overflow: "hidden",
                      textAlign: "center",
                      width: "auto",
                      height: "auto",
                      margin: "auto",
                      // position: "relative",
                      position: `${loader ? "absolute" : "relative"}`,
                      // backgroundColor: "#CBCDCF",
                      borderRadius: "10px",
                      // display: "flex",
                      display: `${loader ? "none" : "flex"}`,
                      justifyContent: "center",
                    }}
                  >
                    {/* <div class="v360-header text-light bg-dark">
                      <span class="v360-header-title">Trial</span>
                      <span class="v360-header-description"></span>
                    </div> */}

                    {!loader && (
                      <div>
                        <ThreeSixty
                          // amount={imageCount - 1}
                          amount={frameCount - 1}
                          // amount={127}
                          // imagePath={originalString}
                          // fileName="{index}.png"
                          // spinReverse
                          frames={frames}
                          loop
                          // autoplay
                          buttonClass="light"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPlayerTrial;
