import React, { useEffect } from "react";
import logo from "./../../../assets/images/logo_font_blue.png";
import { useNavigate, Link } from "react-router-dom";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import { useState } from "react";

function Header() {
  const navigate = useNavigate();
  const alert = useAlert();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const validate = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/validate/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    if (res.ok) {
      // alert.show(`${result.message}`, {
      //   position: positions.TOP_CENTER,
      //   type: types.SUCCESS,
      // });
    } else {
      logout();
      alert.show(`${result.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
    }
  };

  let check = true;
  useEffect(() => {
    if (check) {
      validate();
    }
    check = false;
  }, []);

  // setInterval(() => {
  //   validate();
  // }, 21600);
  // ===================|Logout|==========================
  function logout() {
    // localStorage.clear();
    localStorage.removeItem("logged_in");
    localStorage.removeItem("adminId");
    localStorage.removeItem("cachedData");
    localStorage.removeItem("cachedErrorData");
    localStorage.removeItem("cachedSuccessData");
    localStorage.removeItem("cachedPendingData");
    localStorage.removeItem("cachedInProgressData");
    localStorage.removeItem("cachedClientData");
    localStorage.removeItem("cachedPublicData");
    localStorage.removeItem("diamond_id");
    localStorage.removeItem("supplier_id");
    localStorage.removeItem("client_id");
    localStorage.removeItem("web_url");
    localStorage.removeItem("width");
    localStorage.removeItem("height");
    localStorage.removeItem("left_right");
    localStorage.removeItem("top_bottom");
    localStorage.setItem("logged_res", "");
    alert.show("Logout successfully", {
      position: positions.TOP_CENTER,
      type: types.SUCCESS,
    });
    setTimeout(() => {
      navigate("/");
    }, 500);
  }

  // =====================||===============================
  const [checkSidebar, SetCheckSidebar] = useState(true);
  const toggleSideBar = () => {
    const body = document.getElementsByTagName("body")[0];
    if (checkSidebar) {
      body.classList.add("sidebar-icon-only"); // Add or remove class
      SetCheckSidebar(false);
    } else {
      body.classList.remove("sidebar-icon-only"); // Add or remove class
      SetCheckSidebar(true);
    }
  };
  // =====================|Off Canvas Menu:Mobile View|=====================================
  const OffCanvasMenu = () => {
    const offcanvas = document.getElementById("sidebar");
    offcanvas.classList.toggle("active");
    console.log("off canvas ....");
  };
  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/dashboard">
          <img src={logo} alt="logo" />
        </Link>

        <Link className="navbar-brand brand-logo-mini" to="/dashboard">
          <img src={logo} alt="logo" />
        </Link>

        <button
          className="navbar-toggler navbar-toggler p-2 align-self-center d-none d-lg-flex"
          type="button"
          onClick={toggleSideBar}
          data-toggle="minimize"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul className="navbar-nav mr-lg-2">
          <li className="nav-item  d-none d-lg-flex">
            <Link to="/dashboard" className="nav-link active">
              Dashboard
            </Link>
          </li>
          {/* <li className="nav-item  d-none d-lg-flex">
            <Link to="/dashboard" className="nav-link">
              Top Menu 1
            </Link>
          </li>
          <li className="nav-item  d-none d-lg-flex">
            <Link to="/dashboard" className="nav-link">
              Top Menu 2
            </Link>
          </li> */}
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item dropdown  d-flex">
            <a
              className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
              id="notificationDropdown"
              href="#"
              data-toggle="dropdown"
            >
              <i className="typcn typcn-bell mr-0"></i>
              <span className="count bg-danger">0</span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="notificationDropdown"
            >
              <p className="mb-0 font-weight-normal float-left dropdown-header">
                No Notifications
              </p>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-success">
                    <i className="typcn typcn-info-large mx-0"></i>
                  </div>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject font-weight-normal">
                    Application Error
                  </h6>
                  <p className="font-weight-light small-text mb-0">Just now</p>
                </div>
              </a>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-warning">
                    <i className="typcn typcn-cog mx-0"></i>
                  </div>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject font-weight-normal">
                    Settings
                  </h6>
                  <p className="font-weight-light small-text mb-0">
                    Private message
                  </p>
                </div>
              </a>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-info">
                    <i className="typcn typcn-user-outline mx-0"></i>
                  </div>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject font-weight-normal">
                    New user registration
                  </h6>
                  <p className="font-weight-light small-text mb-0">
                    2 days ago
                  </p>
                </div>
              </a>
            </div>
          </li> */}
          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link dropdown-toggle  pl-0 pr-0"
              href="#"
              data-toggle="dropdown"
              id="profileDropdown"
            >
              <i className="typcn typcn-user-outline mr-0"></i>
              <span className="nav-profile-name">Admin</span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <Link to="/admin-profile" className="dropdown-item">
                <i className="fa fa-user text-primary" aria-hidden="true"></i>
                Profile
              </Link>

              <Link to="/add-member" className="dropdown-item">
                <i
                  className="fa fa-user-plus  text-primary"
                  aria-hidden="true"
                ></i>
                Add Member
              </Link>
              {/* <Link to="/add-client" className="dropdown-item">
                <i
                  className="fa fa-user-plus text-primary"
                  aria-hidden="true"
                ></i>
                Add Client
              </Link> */}
              {/* <Link to="/admin-setting" className="dropdown-item">
                <i className="fa fa-cog text-primary" aria-hidden="true"></i>
                Settings
              </Link> */}
              <a
                role="button"
                className="dropdown-item d-flex align-items-center"
                onClick={logout}
              >
                <i
                  className="fa fa-sign-out text-primary"
                  aria-hidden="true"
                ></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={OffCanvasMenu}
          data-toggle="offcanvas"
        >
          <span className="typcn typcn-th-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Header;
